import { CLIENT } from '../types';

const initialState = {
  loading: true,
  clients: [],
  clientDetails: {
    loading: true,
    data: {
      careAddress: {},
      billingAddress: {},
      user: {},
    },
  },
};

const clientReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CLIENT.LOADING_START:
      return {
        ...state,
        clients: [],
        loading: true,
      };
    case CLIENT.FETCH_SUCCESS:
      return {
        ...state,
        clients: payload,
        loading: false,
      };
    case CLIENT.FETCH_FAILED:
      return {
        ...state,
        clients: [],
        loading: false,
      };
    case CLIENT.FETCH_CLIENT_DETAILS:
      return {
        ...state,
        clientDetails: {
          loading: false,
          data: payload,
        },
      };
    case CLIENT.FETCH_CLIENT_DETAILS_START:
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          loading: true,
        },
      };
    case CLIENT.FETCH_CLIENT_DETAILS_FAIL:
      return {
        ...state,
        clientDetails: initialState.clientDetails,
      };

    case CLIENT.ADD_CLIENT:
      return {
        ...state,
        clients: [payload, ...state.clients],
      };
    case CLIENT.UPDATE_CLIENT:
      const newData = {
        ...payload,
        user: state.clientDetails.data.user,
        clientCarers: state.clientDetails.data.clientCarers,
        clientInvoices: state.clientDetails.data.clientInvoices,
      };
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          data: state.clientDetails.data?.id
            ? newData
            : state.clientDetails.data,
        },
      };

    case CLIENT.DELETE_CLIENT:
      return {
        ...state,
        clients: state.clients.filter((item) => item.id !== payload),
      };
    case CLIENT.ADD_CARER_IN_CLIENT:
      return {
        ...state,
        clientDetails: {
          ...state.clientDetails,
          data: {
            ...state.clientDetails.data,
            clientCarers: [payload, ...state.clientDetails.data.clientCarers],
          },
        },
      };
    default: {
      return state;
    }
  }
};

export default clientReducer;
