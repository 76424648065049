import axios from 'axios';
import { API_URL } from '../../constants';
import { getConfig, handleErrors } from '../../utils/utils';
import { CARER_CLIENT_REPORTS, CLIENT_ACTIVITY_REPORTS } from '../types';

export const setDateRange = (payload) => (dispatch) => {
  dispatch({ type: CLIENT_ACTIVITY_REPORTS.SET_DATE, payload });
};

export const fetchCarerCLientReports = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(`${API_URL}/api/reports`, {
      ...getConfig(),
    });
    // console.log(res.data);
    localStorage.setItem('reports', JSON.stringify(res.data));
    const payload = res.data.data.map((item) => {
      return {
        ...item,
        start: item.StartDate,
        StartDate: new Date(item.StartDate).toLocaleDateString(),
        EndDate: new Date(item.EndDate).toLocaleDateString(),
      };
    });

    dispatch({ type: CARER_CLIENT_REPORTS.FETCH_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: CARER_CLIENT_REPORTS.FETCH_FAILED });

    handleErrors(error);
  }
};
