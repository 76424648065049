import React from 'react';
import emptyInvoice from '../../assests/empty.png';

const NoInvoicesFound = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 48,
        flexDirection: 'column',
      }}
    >
      <img
        alt='invoice'
        src={emptyInvoice}
        style={{
          height: '60vw',
          width: '60vw',
          maxWidth: '280px',
          maxHeight: '280px',
        }}
      />
      <h2 style={{ fontSize: '1.2rem' }}>
        No Invoices found in this category.
      </h2>
    </div>
  );
};

export default NoInvoicesFound;
