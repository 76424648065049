import { Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import styles from '../invoice/InvoiceDetail.module.css';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom';
import React from 'react';
import PropTypes from 'prop-types';

const PageHeader = ({ title, link }) => {
  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'success.light',
        display: 'flex',
        justifyContent: 'space-Between',
        alignItems: 'center',
        pb: 1,
        my: 2,
      }}
    >
      <Link to={link} className={styles.backArrow}>
        <div
          style={{
            cursor: 'pointer',
          }}
        >
          <ArrowBackIosIcon />
        </div>
      </Link>
      <Typography variant='h4' color='text.primary' sx={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
    </Box>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
};

export default PageHeader;
