import React, { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link, useHistory } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { AiTwotoneSetting } from 'react-icons/ai';
import { Button } from '@mui/material';
import styles from '../../app.module.css';

export function MainHeader({ toggleDrawer }) {
  const [path, setPath] = useState('/');

  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();
  useEffect(() => {
    const unListen = history.listen(({ pathname }) => {
      setPath(pathname);
    });
    return unListen;
  }, []);

  return (
    <div className={styles.header}>
      <div style={{ display: 'flex' }}>
        <Button
          onClick={toggleDrawer}
          style={isSmall ? { display: 'block' } : { display: 'none' }}
          className={styles.menuButton}
        >
          <MenuIcon />
        </Button>
        <h1>seakr</h1>
      </div>
      <Link to={path === '/setting' ? '/' : '/setting'}>
        {path === '/setting' ? (
          <AiTwotoneSetting className={styles.setting} />
        ) : (
          <FiSettings className={styles.setting} />
        )}
      </Link>
    </div>
  );
}
