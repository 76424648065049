import axios from 'axios';
import { handleErrors } from '../../utils/utils';
import { USER } from '../types';
import { API_URL } from '../../constants';

const USER_ID = 'userId';
const TOKEN = 'token';

const normalUser = ['hr@neolen'];

const isSuperUser = (email = '') => {
  for (let i = 0; i < normalUser.length; i++) {
    const item = normalUser[i];
    if (email.includes(item)) {
      return false;
    }
  }
  return true;
};

export const logOut = () => async (dispatch) => {
  try {
    localStorage.removeItem(USER);
    dispatch({ type: USER.LOGOUT });
  } catch (error) {
    handleErrors(error);
  }
};

export const login =
  (data, onComplete = () => {}) =>
  async (dispatch) => {
    try {
      const res = await axios.post(`${API_URL}/api/auth/signin`, data);

      const user = res.data.data?.user;
      const token = res.data.data?.token;

      if (user.role !== 0) {
        onComplete(false, 'Seakr not found');
        return;
      }

      localStorage.setItem(USER_ID, user.id);
      localStorage.setItem(TOKEN, token);

      const payload = {
        ...user,
        token,
        superUser: user.financeRole === false,
      };

      onComplete(true);
      dispatch({ type: USER.FETCH_SUCCESS, payload });
    } catch (error) {
      dispatch({ type: USER.FETCH_FAILED });
      const msg = error.response?.data?.errors?.message || error.message;
      console.error(msg);
      onComplete(false, msg);
    }
  };
export const getUser = () => async (dispatch) => {
  try {
    const userId = localStorage.getItem(USER_ID);
    const token = localStorage.getItem(TOKEN);

    if (!userId) {
      dispatch({ type: USER.FETCH_FAILED });
      return;
    }

    const res = await axios.get(`${API_URL}/api/users/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const user = {
      ...res.data.data,
      superUser: res.data.data.financeRole === false,
    };

    const payload = { ...user, token: res.data.data?.token };
    dispatch({ type: USER.FETCH_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: USER.FETCH_FAILED });
  }
};
