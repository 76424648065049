import { CircularProgress } from '@mui/material';
import React from 'react';

const TextWithLoader = ({ text, loading }) => {
  if (loading) {
    return (
      <CircularProgress style={{ margin: '1px' }} color='inherit' size={24} />
    );
  }
  return <>{text}</>;
};

export default TextWithLoader;
