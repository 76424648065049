// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CarerDetail_footerButton__3gM1i {\n  font-size: 2rem;\n  border: solid 2px hsl(0, 13%, 95%);\n  padding: 1rem;\n  color: #567d37;\n  margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["webpack://src/components/carer/listofcarers/CarerDetail.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kCAAkC;EAClC,aAAa;EACb,cAAc;EACd,mBAAmB;AACrB","sourcesContent":[".footerButton {\n  font-size: 2rem;\n  border: solid 2px hsl(0, 13%, 95%);\n  padding: 1rem;\n  color: #567d37;\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerButton": "CarerDetail_footerButton__3gM1i"
};
export default ___CSS_LOADER_EXPORT___;
