import { MainHeader } from './../components/common/MainHeader';
import React, { useEffect, useState } from 'react';
import MainMenu from '../MainMenu';
import MainDisplay from '../MainDisplay';

import styles from '../app.module.css';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import SignIn from '../SignIn';
import { connect } from 'react-redux';
import { getUser } from '../redux/actions/authActions';
import LoadingFull from '../components/common/LoadingFull';
import Failed from "../components/Failed/Failed";
import OnBoarding from "../components/Success/onboarding";

const Navigator = ({ user, loading, getUser }) => {
  const [sideBar, setSideBar] = useState(true);

  useEffect(() => {
    getUser();
  }, [getUser]);

  if (loading) {
    return <LoadingFull />;
  }

  if (!user) {
    return (
      <Router>
        <Switch>
          <Route exact path='/'>
            <SignIn setVerified={() => {}} />
          </Route>
          <Route exact path='/stripe-failed'>
            <Failed/>
          </Route>
          <Route exact path='/stripe-success'>
            <OnBoarding/>
          </Route>
          <Route path='*'>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    );
  }

  const toggleDrawer = () => {
    setSideBar(!sideBar);
  };
  return (
    <Router>
      <div
        style={{
          padding: 16,
          height: '90vh',
          maxWidth: 1240,
          margin: 'auto',
        }}
      >
        <MainHeader toggleDrawer={toggleDrawer} />
        <div className={styles.divide}>
          <MainMenu sideBar={sideBar} toggleDrawer={toggleDrawer} />
          <MainDisplay />
        </div>
      </div>
    </Router>
  );
};

const mapStateToProps = ({ auth }) => ({
  user: auth.user,
  loading: auth.loading,
});

export default connect(mapStateToProps, { getUser })(Navigator);
