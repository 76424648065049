import axios from 'axios';
import { getConfig, handleErrors } from '../../utils/utils';
import { CARER } from '../types';
import { API_URL } from '../../constants';
import { toast } from 'react-toastify';

export const getAllCarers = (query) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/api/carers`, {
      params: query,
      ...getConfig(),
    });
    const payload = res.data.data;
    dispatch({ type: CARER.FETCH_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: CARER.FETCH_FAILED });
    handleErrors(error);
  }
};

export const createClientCarerConfig = (sol) => async () => {
  const body = {
    carerId: sol.carerId,
    clientId: sol.clientId,
    payment: {
      grossCarerFee: sol.grossCarerFee,
      seakrFee: sol.seakrFee,
      netCarerPayment: sol.netCarerPayment,
    },
    tax: {
      grossCarerTax: sol.grossCarerTax,
      seakrTax: sol.seakrTax,
      netTax: sol.netTax,
    },
    holidayRates: {
      grossCarerRates: sol.holidayGrossCarerRates,
      seakrRates: sol.holidaySeakrRates,
      netCarerPayment: sol.holidaysNetCarerPayment,
    },
    customRates: {
      grossCarerFee: sol.customGrossCarerFee || 0,
      seakrFee: sol.customSeakrFee || 0,
      customRatesRule: sol.customRatesRule,
      netCarerPayment: sol.customNetCarerPayment || 0,
    },
  };
  const res = await axios.post(
    `${API_URL}/api/carer-config`,
    body,
    getConfig()
  );
  return res;
  // const promise1 = axios.post(`${API_URL}/api/carer-config`, body, getConfig());
  // const promise2 = getCarerById(sol.carerId);
  // const promise3 = getClientById(sol.clientId);
  // const [res, carer, client] = await Promise.all([
  //   promise1,
  //   promise2,
  //   promise3,
  // ]);
  // const data = {
  //   ...res.data.data,
  //   carer,
  //   client,
  // };
  // return data;
};

export const getCarerById = async (id) => {
  const res = await axios.get(`${API_URL}/api/carers/${id}`, getConfig());
  return res.data.data;
};

export const getUserClients = async (carerId) => {
  const res = await axios.get(`${API_URL}/api/carer-config`, getConfig());
  return res.data.data.filter((item) => item.carerId === carerId);
};

export const addCarerClient =
  (body, onComplete = () => {}) =>
  async (dispatch) => {
    try {
      const payload = await createClientCarerConfig(body);
      dispatch({ type: CARER.ADD_CLINT_IN_CARER, payload });
      onComplete(true);
    } catch (error) {
      const msg = error.response?.data?.errors?.message || error.message;
      console.error(msg);
      onComplete(false, msg);
    }
  };

export const getUserCarer = async (clientId) => {
  const res = await axios.get(`${API_URL}/api/carer-config`, getConfig());
  return res.data.data.filter((item) => item.clientId === clientId);
};

const getInvoicesByCarerId = async (id) => {
  try {
    const res = await axios.get(`${API_URL}/api/invoices/`, {
      params: {
        published: true,
        carerId: id,
      },
      ...getConfig(),
    });

    return res.data?.data;
  } catch (error) {
    console.log(error.message);
    return [];
  }
};

export const getCarerDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: CARER.FETCH_CARER_DETAILS_START });

    const [res, carerClients, carerInvoices] = await Promise.all([
      axios.get(`${API_URL}/api/carers/${id}`, getConfig()),
      getUserClients(id),
      getInvoicesByCarerId(id),
    ]);

    const carer = res.data.data;
    const payload = {
      ...carer,
      carerClients,
      carerInvoices,
    };
    dispatch({ type: CARER.FETCH_CARER_DETAILS, payload });
    return payload;
  } catch (error) {
    dispatch({ type: CARER.FETCH_CARER_DETAILS_FAIL });
    handleErrors(error);
  }
};

export const createCarer =
  (body, onComplete = () => {}) =>
  async (dispatch) => {
    const {
      name,
      email,
      reference,
      company,
      phone,
      postCode,
      country,
      town,
      profile,
      bankName,
      sortCode,
      accountName,
      accountNumber,
      iban,
      swift,
      bic,
      routingNumber,
    } = body;
    // create or get user
    let userId = null;
    let user = null;
    try {
      const authRes = await axios.post(
        `${API_URL}/api/auth/create-account`,
        { name, email, role: '1' },
        getConfig()
      );

      user = authRes.data.data;
      userId = user.id;
    } catch (e) {
      if (e.response.status === 409) {
        console.log(e.response.data);
        userId = e.response.data.userId;
      } else {
        toast.error(e.message);
      }
    }

    if (!userId) {
      toast.error('Not able to create user!');
    }

    try {
      const carerRes = await axios.post(
        `${API_URL}/api/carers`,
        {
          userId,
          company,
          reference,
          address: {
            postCode,
            country,
            town,
          },
          phone,
          profile,
          bankAccount: {
            bankName,
            accountHolderName: accountName,
            sortCode,
            accountNumber,
            bic,
            swift,
            iban,
            routingNumber,
          },
        },
        getConfig()
      );
      const payload = { ...carerRes.data.data, user };
      dispatch({ type: CARER.ADD_CARER, payload });
      onComplete(true);
    } catch (error) {
      console.log(error);
      const msg = error.response?.data?.errors?.message || error.message;
      console.error(msg);
      onComplete(false, msg);
    }
  };

export const updateCarer =
  (id, body, modifyBankDetails = false, onComplete = () => {}) =>
  async (dispatch) => {
    const {
      userId,
      email,
      phone,
      reference,
      company,
      country,
      town,
      postCode,
      bankName,
      sortCode,
      accountName,
      accountNumber,
      iban,
      swift,
      bic,
      routingNumber,
    } = body;

    try {
      const carerRes = await axios.patch(
        `${API_URL}/api/carers/${id}`,
        {
          userId,
          company,
          reference,
          address: {
            country,
            town,
            postCode,
          },
          phone,
          bankAccount: modifyBankDetails
            ? {
                bankName,
                accountHolderName: accountName,
                sortCode,
                accountNumber,
                bic,
                swift,
                iban,
                routingNumber,
              }
            : {},
        },
        getConfig()
      );
      const payload = { ...carerRes.data.data };
      dispatch({ type: CARER.UPDATE_CARER, payload });
      getAllCarers()(dispatch);
      onComplete(true);
    } catch (error) {
      const msg = error.response?.data?.errors?.message || error.message;
      console.error(msg);
      onComplete(false, msg);
    }
  };

export const patchCarer =
  (id, body, onComplete = () => {}) =>
  async (dispatch) => {
    try {
      const carerRes = await axios.patch(
        `${API_URL}/api/carers/${id}`,
        body,
        getConfig()
      );
      const payload = { ...carerRes.data.data };
      dispatch({ type: CARER.UPDATE_CARER, payload });
      getAllCarers()(dispatch);
      onComplete(true);
    } catch (error) {
      const msg = error.response?.data?.errors?.message || error.message;
      console.error(msg);
      onComplete(false, msg);
    }
  };
