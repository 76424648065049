import React from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SubHeading from '../common/SubHeading';
import { getFormattedString } from '../../utils/utils';
import { colors } from '../../constants/colors';
import { API_URL } from '../../constants';

const Expenses = ({ items }) => {
  return (
    <Container
      sx={{
        borderBottom: 1,
        borderColor: 'grey.500',
        pb: 2,
        mb: 4,
      }}
    >
      <SubHeading title='Mileage Cost' />

      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Day</TableCell>
              <TableCell align='center'>Distance</TableCell>
              <TableCell align='center'>Cost</TableCell>
              <TableCell align='center'>Note</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              .filter((item) => item.type === 'Mileage')
              .map((item) => (
                <TableRow
                  key={item.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align='center'>
                    <Typography
                      variant='h6'
                      fontSize='1rem'
                      color='text.primary'
                    >
                      {item.day}
                    </Typography>
                  </TableCell>
                  <TableCell align='center'>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        bgcolor: 'primary.background',
                        p: 1,
                        borderRadius: '.2rem',
                      }}
                    >
                      <h2 style={{ color: colors.primary }}>{item.distance}</h2>{' '}
                    </Box>
                  </TableCell>
                  <TableCell align='center'>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        bgcolor: 'primary.background',
                        p: 1,
                        borderRadius: '.2rem',
                      }}
                    >
                      <h2 style={{ color: colors.primary }}>{item.cost}</h2>{' '}
                    </Box>
                  </TableCell>
                  <TableCell align='right'>
                    <p style={{ textAlign: 'right', fontSize: '1rem' }}>
                      {item.note}
                    </p>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SubHeading title='Extra Expenses' />

      <Grid container>
        {items
          .filter((item) => item.type === 'Other Expenses')
          .map((item) => {
            return (
              <Grid xs={12} md={4}>
                <Box
                  sx={{
                    width: '95%',
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: 'primary.background',
                      py: 2,
                    }}
                  >
                    <Typography
                      variant='h6'
                      color='text.primary'
                      sx={{
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      {item.type}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      border: 2,
                      borderColor: 'grey.400',
                      borderRadius: '5px',
                      my: 2,
                      px: 2,
                    }}
                  >
                    <Typography
                      variant='h6'
                      color='text.primary'
                      sx={{
                        fontWeight: 'bold',
                        borderBottom: 1,
                        borderColor: 'primary.profile',
                        mt: 1,
                      }}
                    >
                      {item.day}
                    </Typography>
                    {item.fileName && (
                      <Typography
                        variant='body2'
                        color='text.primary'
                        sx={{
                          bgcolor: 'primary.background',
                          fontWeight: 'bold',
                          border: 1,
                          borderColor: 'grey.500',
                          display: 'flex',
                          borderRadius: '5px',
                          justifyContent: 'space-evenly',
                          alignItems: 'center',
                          my: 1,
                          py: 1,
                        }}
                      >
                        {getFormattedString(item.fileName)}
                        <a
                          target='_blank'
                          href={`${API_URL}/api/files/data/mileage/${item.fileName}`}
                        >
                          <VisibilityIcon sx={{ color: 'primary.profile' }} />
                        </a>
                      </Typography>
                    )}
                    {item.distance && (
                      <Typography
                        variant='body2'
                        color='text.primary'
                        sx={{
                          bgcolor: 'primary.background',
                          fontWeight: 'bold',
                          border: 1,
                          borderColor: 'grey.500',
                          display: 'flex',
                          borderRadius: '5px',
                          justifyContent: 'space-around',
                          alignItems: 'center',
                          my: 1,
                          py: 1,
                        }}
                      >
                        <h3>Mileage</h3>
                        <h2 style={{ color: colors.primary }}>
                          {item.distance}
                        </h2>
                      </Typography>
                    )}
                    <Typography variant='body2' color='grey.500'>
                      Note:
                    </Typography>
                    <TextField
                      color='success'
                      variant='outlined'
                      label=''
                      multiline
                      rows={3}
                      value={item.note}
                      disabled
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        my: 1,
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
};

export default Expenses;
