import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { logOut } from '../../redux/actions/authActions';
import { connect } from 'react-redux';
import PageHeader from '../../components/common/PageHeader';
const Setting = ({ logOut }) => {
  return (
    <Container maxWidth='md'>
      <PageHeader title='Settings' link='/' />

      <Link to='/account'>
        <Box
          sx={{
            border: 2,
            borderRadius: '10px',
            borderColor: 'grey.500',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 4,
            py: 1,
            px: 1,
          }}
        >
          <Typography
            variant='h6'
            color='text.primary'
            sx={{ fontWeight: 'bold' }}
          >
            Account
          </Typography>
          <AccountCircleIcon
            fontSize='large'
            sx={{ color: 'primary.profile' }}
          />
        </Box>
      </Link>
      <Link to='/seakr-help-center'>
        <Box
          sx={{
            border: 2,
            borderRadius: '10px',
            borderColor: 'grey.500',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mt: 4,
            py: 1,
            px: 1,
          }}
        >
          <Typography
            variant='h6'
            color='text.primary'
            sx={{ fontWeight: 'bold' }}
          >
            seakr Help Center
          </Typography>
          <HelpOutlineIcon fontSize='large' sx={{ color: 'primary.profile' }} />
        </Box>
      </Link>
      
      {/* <Link to = '/privacy-policy'>
        <Box
        sx={{
          border: 2,
          borderRadius: '10px',
          borderColor: 'grey.500',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 4,
          py: 1,
          px: 1,
        }}>
       <Typography
            variant='h6'
            color='text.primary'
            sx={{ fontWeight: 'bold' }}
          >
            Privacy Policy
          </Typography>
          <HelpOutlineIcon fontSize='large' sx={{ color: 'primary.profile' }} />
        </Box>
        </Link> */}
      
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
        }}
      >
        <Box
          component='button'
          onClick={() => logOut()}
          sx={{
            width: '20%',
            border: 2,
            borderRadius: '8px',
            borderColor: 'grey.500',
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            py: 1,
            mt: 15,
            cursor: 'pointer',
          }}
        >
          <Typography
            variant='h6'
            color='text.primary'
            sx={{ fontWeight: 'bold' }}
          >
            Log Out
          </Typography>
          <LogoutIcon fontSize='large' sx={{ color: 'primary.profile' }} />
        </Box>
      </Box>
    </Container>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { logOut })(Setting);
