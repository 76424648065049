import React, { useState } from 'react';
import TextInput from '../../components/clients/SetupClient/textInput';
import { CircularProgress } from '@mui/material';
import { Button, Container, Typography, Box, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmail } from '../../utils/utils';
import { createCarer } from '../../redux/actions/carerActions';
import { connect } from 'react-redux';
import PageHeader from '../../components/common/PageHeader';
import { useFormik } from 'formik';

const CarerCreate = ({ createCarer }) => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      reference: '',
      company: '',
      phone: '',
      postCode: '',
      country: '',
      town: '',
      profile: 'ffwefewfew.jpg',
      bankName: '',
      sortCode: '',
      accountName: '',
      accountNumber: '',
      iban: '',
      swift: '',
      bic: '',
      routingNumber: '',
    },
    onSubmit: (values) => {
      if (!isEmail(values.email)) {
        toast('Enter a valid email!');
        return;
      }
      // if (isContact(values.phone)) {
      //   toast('Enter a valid phone number!');
      //   return;
      // }
      setLoading(true);
      createCarer(values, (success, msg) => {
        setLoading(false);
        if (success) {
          toast.success('carer created successfully');
          history.push('/carer');
          return;
        } else {
          toast(msg);
        }
      });
    },
  });
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  return (
    <Container maxWidth='md'>
      <PageHeader title='Setup carer' link={`/carer`} />
      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          sx={{
            mt: 3,
          }}
        >
          <Grid item xs={12} md={5}>
            <TextInput
              heading='Name'
              name='name'
              value={formik.values.name}
              required
              onChange={formik.handleChange}
            />
            <TextInput
              heading='Company Name'
              name='company'
              required
              value={formik.values.company}
              onChange={formik.handleChange}
            />
            <TextInput
              heading='Email'
              name='email'
              required
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <TextInput
              heading='Contact Number'
              name='phone'
              required
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
            <TextInput
              heading='carer Reference'
              name='reference'
              required
              value={formik.values.reference}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={5}>
          <TextInput
              heading='Company/carer Address'
              required
              name='country'
              value={formik.values.country}
              onChange={formik.handleChange}
            />
          <TextInput
              heading='County/Town (Company/carer Address)'
              required
              name='town'
              value={formik.values.town}
              onChange={formik.handleChange}
            />
             <TextInput
              heading='Post Code (Company/carer Address)'
              required
              name='postCode'
              value={formik.values.postCode}
              onChange={formik.handleChange}
            />
           
            
            
            
            
          </Grid>
        </Grid>

        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'success.light',
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            pb: 1,
            my: 2,
          }}
        >
          <Typography
            variant='h4'
            color='text.primary'
            sx={{ fontWeight: 'bold' }}
          >
            Bank Details
          </Typography>
        </Box>
        <Grid
          container
          sx={{
            mt: 3,
            pb: 3,
            borderBottom: 3,
            borderColor: 'primary.profile',
          }}
        >
          <Grid item xs={12} md={5}>
            <TextInput
              heading='Name of Bank'
              name='bankName'
              value={formik.values.bankName}
              onChange={formik.handleChange}
              required
            />
            <TextInput
              heading='Name on Account'
              name='accountName'
              value={formik.values.accountName}
              onChange={formik.handleChange}
              required
            />
            <TextInput
              heading='Routing Number/IFSC number'
              name='routingNumber'
              value={formik.values.routingNumber}
              onChange={formik.handleChange}
              required
            />
            <TextInput
              heading='Swift'
              name='swift'
              value={formik.values.swift}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={5}>
            <TextInput
              heading='Sort Code'
              name='sortCode'
              value={formik.values.sortCode}
              required
              onChange={formik.handleChange}
            />
            <TextInput
              heading='Account Number'
              name='accountNumber'
              value={formik.values.accountNumber}
              required
              onChange={formik.handleChange}
            />
            <TextInput
              heading='BIC'
              name='bic'
              value={formik.values.bic}
              onChange={formik.handleChange}
            />
            <TextInput
              heading='IBAN'
              name='iban'
              value={formik.values.iban}
              onChange={formik.handleChange}
            />
            <Button
              variant='contained'
              size='large'
              color='primary'
              type='submit'
              sx={{ my: 8, width: '100%' }}
            >
              {loading ? (
                <CircularProgress color='inherit' size={24} />
              ) : (
                'Finish'
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default connect(null, { createCarer })(CarerCreate);
