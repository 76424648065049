import { Box, Button, Card, Container, Modal, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { BsDownload } from 'react-icons/bs';
import { IoToday } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../components/common/PageHeader';
import styles from './CarerTable.module.css';
import { fetchClientActivityReports } from '../redux/actions/clientActivityReportsActions';
import { downloadAsExcel, filterByDate } from '../utils/utils';
import WeekCalendar from './WeekCalender';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import SelectDate from '../components/common/SelectDate';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
};

const ClientActivityReport = () => {
  const { reports, loading, dateRange } = useSelector(
    ({ clientActivityReports }) => clientActivityReports
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchClientActivityReports());
  }, []);

  return (
    <Container maxWidth='md'>
      <PageHeader title='Client Activity Report' link='/report' />

      <Box
        sx={{
          display: 'flex',
          mb: 2,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <SelectDate />
        <BsDownload
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={() => downloadAsExcel(reports)}
          className={styles.footerButton}
        />
      </Box>

      {reports && (
        <TableContainer component={Paper} className={styles.shown}>
          <table>
            <thead>
              <tr
                style={{
                  backgroundColor: 'rgb(94,137,60)',
                  border: '1px solid black',
                  width: 'max-content',
                }}
              >
                {/* <div> */}
                <td className={styles.header}>
                  <Box sx={{ width: 'max-content' }}>Client Reference</Box>
                </td>{' '}
                <td className={styles.header}>
                  <Box sx={{ width: 'max-content' }}>Client contact name</Box>
                </td>{' '}
                <td className={styles.header}>
                  {' '}
                  <Box sx={{ width: 'max-content' }}>Date</Box>
                </td>
                <td className={styles.header}>
                  <Box sx={{ width: 'max-content' }}>carer contact name</Box>
                </td>
                <td className={styles.header}>
                  {' '}
                  <Box sx={{ width: 'max-content' }}>
                    Xero Invoice/Bill Reference
                  </Box>
                </td>
                <td className={styles.header}>
                  {' '}
                  <Box sx={{ width: 'max-content' }}>
                    carer Original Invoice Ref
                  </Box>
                </td>
                <td className={styles.header}>
                  {' '}
                  <Box sx={{ width: 'max-content' }}>Service</Box>
                </td>
                <td className={styles.header}>
                  {' '}
                  <Box sx={{ width: 'max-content' }}>Quantity</Box>
                </td>
                <td className={styles.header}>
                  {' '}
                  <Box sx={{ width: 'max-content' }}>Price</Box>
                </td>
                <td className={styles.header}>
                  {' '}
                  <Box sx={{ width: 'max-content' }}>Sum of Total</Box>
                </td>
              </tr>
            </thead>

            <tbody>
              {reports ? (
                filterByDate(reports, dateRange).map((row, i) => (
                  <tr
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <td>{row.ClientRef}</td>
                    <td>{row.ClientContactName}</td>
                    <td>{row.Date?.slice(0, 10)}</td>
                    <td>{row.CarerContactName}</td>
                    <td>{row.XeroInvoiceRef}</td>
                    <td>{row.CarerOriginalInvoiceRef}</td>
                    <td>{row.Service}</td>
                    <td>{row.Quantity}</td>
                    <td>{row.Price}</td>
                    <td>{row.Total}</td>
                  </tr>
                ))
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    // border: "2px solid green",
                    width: '325%',
                  }}
                >
                  <Card
                    style={{
                      margin: 'auto',
                      paddingTop: '4rem',
                      paddingBottom: '4rem',
                      textAlign: 'center',
                      // border: "2px solid black",
                      fontSize: '2rem',
                    }}
                  >
                    No invoices yet!
                  </Card>
                </div>
              )}
            </tbody>
          </table>
        </TableContainer>
      )}
    </Container>
  );
};

export default ClientActivityReport;
