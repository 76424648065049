import React from 'react';
import './failed.css';
import ErrorIcon from '@mui/icons-material/Error';

function Failed() {
  return (

    <div className="thankyou-body">

      <div className="thankyou-logo"><img src="./public/logo.png"></img></div>

      <div className="icon">
        <center><ErrorIcon  style={{ color: "red",size:"70" }} /></center>
      </div>
      <h1 className="thankyou-heading-1">Failed</h1>
      <p className="thankyou-p">Please contact seakr support to get the link. <br/> <a href="mailto:admin@seakr.com">Contact your seakr</a></p>
      {/* <h1 className="thankyou-heading redirecting">Redirecting...</h1> */}


    </div>
  );
}

export default Failed;
