import * as React from 'react';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Image from './assests/seakrImage.jpeg';
import { login } from './redux/actions/authActions';
import { connect } from 'react-redux';
import { CircularProgress } from '@mui/material';
import axios from 'axios';
import { API_URL } from './constants';
import { isEmail } from './utils/utils';

const SignIn = ({ login }) => {
  const [data, setData] = useState({
    email: '',
    password: '',
  });
  const [forgetPassword, setForgetPassword] = useState(false);

  const toggleForgetPassword = () => {
    setData({
      ...data,
      email: '',
    });
    setForgetPassword(!forgetPassword);
  };

  const sendVerificationEmail = async () => {
    if (!isEmail(data.email)) {
      toast.error('Enter a valid email');
      return;
    }
    try {
      const res = await axios.post(`${API_URL}/api/auth/forget-password/`, {
        email: data.email,
      });
      if (res.data.data?.id) {
        toast.success('verification mail send');
        setForgetPassword(false);
        return;
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'User does not exists');
    }
  };

  const [btnLoading, setBtnLoading] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (forgetPassword) {
      sendVerificationEmail();
      return;
    }
    setBtnLoading(true);
    login(data, (success, msg) => {
      if (!success) {
        toast(msg);
      }
      setBtnLoading(false);
    });
  };
  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: 'primary.background2',
        height: '100vh',
      }}
    >
      <ToastContainer />
      <Container
        style={{
          backgroundImage: `url(${Image})`,
          backgroundSize: '85% 100%',
          backgroundPosition: 'left',
          backgroundRepeat: 'no-repeat',
          marginLeft: '0',
        }}
      >
        <Typography
          component='h1'
          variant='h2'
          sx={{ color: 'white' }}
          style={{
            

            
            fontWeight: 600,
            position: 'absolute',
            top: '5%',
            left: '5%',
         
          }}
        >
          Sign-In
        </Typography>
      </Container>
      <Container
        component='main'
        style={{
          
          position: 'relative',
          right: '25%',
          top: '50vh',
          transform: 'translateY(-50%)',
          backgroundColor: 'white',
          blockSize: 'fit-content',
          borderRadius: '10px',
          width: '24rem',
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
        }}
        sx={{
          py: 5,
        }}
      >
        <CssBaseline />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box
            component='form'
            onSubmit={handleSubmit}
            sx={{ mt: 1, width: '100%' }}
          >
            <Box>
              <Typography
                component='h1'
                variant='body1'
                sx={{
                  color: 'primary.main',
                }}
                style={{ fontWeight: 600 }}
              >
                Email
              </Typography>
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                placeholder='Email'
                value={data.email}
                name='email'
                onChange={handleInputChange}
                autoComplete='email'
                autoFocus
              />
            </Box>
            {forgetPassword ? (
              <p
                style={{
                  marginTop: '1rem',
                  marginBottom: '3rem',
                }}
              >
                Enter your account email, We will send you a recovery email
                shortly.
              </p>
            ) : (
              <Box>
                <Typography
                  component='h1'
                  variant='body1'
                  sx={{
                    color: 'primary.main',
                  }}
                  style={{ fontWeight: 600 }}
                >
                  Password
                </Typography>

                <TextField
                  required
                  margin='normal'
                  fullWidth
                  name='password'
                  placeholder='Password'
                  value={data.password}
                  onChange={handleInputChange}
                  type='password'
                  id='password'
                  autoComplete='current-password'
                />
              </Box>
            )}
            <p
              onClick={toggleForgetPassword}
              style={{
                cursor: 'pointer',
                color: '#5e893c',
              }}
            >
              {!forgetPassword ? 'Forgot Password?' : 'Login'}
            </p>
            <Button
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2, py: 1, fontSize: '1.2rem' }}
              type='submit'
            >
              {btnLoading ? (
                <CircularProgress color='inherit' size={24} />
              ) : forgetPassword ? (
                'Send mail '
              ) : (
                'Sign In'
              )}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, { login })(SignIn);
