import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Box, Button, Grid } from '@mui/material';
import { connect } from 'react-redux';
import TextInput from './SetupClient/textInput';
import PageHeader from '../common/PageHeader';
import ProfileDetail from '../common/ProfileDetail';
import SubHeading from '../common/SubHeading';
import { createCarer } from '../../redux/actions/carerActions';
import { getCarerDetails } from '../../redux/actions/carerActions';
import { getCarerConfigurationById } from '../../redux/actions/invoiceActions';
import { updateCarerConfiguration } from '../../redux/actions/invoiceActions';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import TextWithLoader from '../common/TextWithLoader';

const AssignedCarer = ({
  carerDetails,
  getCarerDetails,
  getClientDetails,
  clientLoading,
  clients,
  createCarer,
  match,
}) => {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const initialValues = (res) => {
    return {
      id: match.params.id,
      clientId: res.clientId,
      clientName: res.client.user.name,
      clientAddress: res.client.careAddress.country,
      carerId: res.carerId,
      carerName: res.carer.user.name,
      carerAddress: res.carer.address.country,
      grossCarerFee: res.payment.grossCarerFee,
      seakrFee: res.payment.seakrFee,
      netCarerPayment: res.payment.netCarerPayment,
      grossCarerTax: res.tax.grossCarerTax,
      seakrTax: res.tax.seakrTax,
      netTax: res.tax.netTax,
      holidayGrossCarerRates: res.holidayRates.grossCarerRates,
      holidaySeakrRates: res.holidayRates.seakrRates,
      holidaysNetCarerPayment: res.holidayRates.netCarerPayment,
      customGrossCarerFee: res.customRates.grossCarerFee,
      customSeakrFee: res.customRates.seakrFee,
      customNetCarerPayment: res.customRates.netCarerPayment,
      customRatesRule: res.customRates.customRatesRule,
    };
  };

  useEffect(() => {
    getCarerConfigurationById(match.params.id).then((res) => {
      setValues(initialValues(res));
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <Container maxWidth='md'>
      <PageHeader title='Assigned Details' link='/carer' />
      <Grid
        container
        spacing={8}
        sx={{
          mb: 8,
        }}
      >
        <Grid item xs={12} md={5}>
          <ProfileDetail
            type='Carer'
            name={values.carerName}
            address={values.carerAddress}
          />
        </Grid>
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={5}>
          <ProfileDetail
            type='Client'
            name={values.clientName}
            address={values.clientAddress}
          />
        </Grid>
      </Grid>
      <SubHeading title='Payment' />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          updateCarerConfiguration(match.params.id, values)
            .then(() => {
              setLoading(false);
              toast('Updated successfully');
              history.goBack();
            })
            .catch((error) => {
              setLoading(false);
              toast(error.message);
            });
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Gross Carer Fee (Client Fee)'
              name='grossCarerFee'
              value={values.grossCarerFee}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='Client Fee VAT %'
              name='grossCarerTax'
              value={values.grossCarerTax}
              onChange={handleInputChange}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Seakr Fee (Platform Fees)'
              name='seakrFee'
              value={values.seakrFee}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='Platform Fee VAT %'
              name='seakrTax'
              value={values.seakrTax}
              onChange={handleInputChange}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Net Carer Payment (Carer Fee)'
              name='netCarerPayment'
              value={values.netCarerPayment}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='Carer Fee VAT %'
              name='netTax'
              value={values.netTax}
              onChange={handleInputChange}
              required
            />
          </Grid>
        </Grid>

        <SubHeading title='Holidays Rate' />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Gross Carer Fee'
              name='holidayGrossCarerRates'
              value={values.holidayGrossCarerRates}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='Client Fee VAT %'
              name='grossCarerTax'
              value={values.grossCarerTax}
              onChange={handleInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Seakr Fee'
              name='holidaySeakrRates'
              value={values.holidaySeakrRates}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='Platform Fee VAT %'
              name='seakrTax'
              value={values.seakrTax}
              onChange={handleInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Net Carer Payment'
              name='holidaysNetCarerPayment'
              value={values.holidaysNetCarerPayment}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='Carer Fee VAT %'
              name='netTax'
              value={values.netTax}
              onChange={handleInputChange}
              disabled
            />
          </Grid>
        </Grid>

        <SubHeading title='Custom Rates' />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Gross Carer Fee'
              name='customGrossCarerFee'
              value={values.customGrossCarerFee}
              onChange={handleInputChange}
             // required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='Client Fee VAT %'
              name='grossCarerTax'
              value={values.grossCarerTax}
              onChange={handleInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Seakr Fee'
              name='customSeakrFee'
              value={values.customSeakrFee}
              onChange={handleInputChange}
              //required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='Platform Fee VAT %'
              name='seakrTax'
              value={values.seakrTax}
              onChange={handleInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Net Carer Payment'
              name='customNetCarerPayment'
              value={values.customNetCarerPayment}
              onChange={handleInputChange}
              //required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='Carer Fee VAT %'
              name='netTax'
              value={values.netTax}
              onChange={handleInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}></Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Custom Miles Rate'
              name='customRatesRule'
              value={values.customRatesRule}
              onChange={handleInputChange}
              //required
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'end',
          }}
        >
          <Button
            variant='contained'
            size='large'
            color='primary'
            type={'submit'}
            sx={{ my: 3, width: '40%' }}
          >
            <TextWithLoader text='Update Details' loading={loading} />
          </Button>
        </Box>
      </form>
    </Container>
  );
};
const mapStateToProps = ({
  carer: {
    carerDetails: { loading, data },
  },
  client: { clients },
}) => ({
  clientLoading: loading,
  carerDetails: data,
  clients,
});
export default connect(mapStateToProps, { createCarer, getCarerDetails })(
  AssignedCarer
);
