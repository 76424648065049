import { Button, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; //
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../constants/colors';
import { setDateRange } from '../../redux/actions/carerClientReportsActions';

const initialValue = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
};

const showDateRange = ({ startDate = new Date(), endDate = new Date() }) => {
  return `${startDate.toLocaleDateString()}  -  ${endDate.toLocaleDateString()}`;
};

const SelectDate = () => {
  const [selectionRange, setSelectionRange] = useState(initialValue);

  const { dateRange } = useSelector(
    ({ clientActivityReports }) => clientActivityReports
  );

  const dispatch = useDispatch();

  const handleDateSelect = (date) => {
    setSelectionRange(date.selection);
  };

  useEffect(() => {
    dispatch(setDateRange(null));
  }, []);

  const [visibility, setVisibility] = useState(false);
  return (
    <>
      {/*<div*/}
      {/*  style={{*/}
      {/*    border: '1px solid rgba(0,0,0,.2)',*/}
      {/*    padding: '.5rem',*/}
      {/*    paddingLeft: '1rem',*/}
      {/*    paddingRight: '1rem',*/}
      {/*    borderRadius: '4px',*/}
      {/*    cursor: 'pointer',*/}
      {/*  }}*/}
      {/*  onClick={() => setVisibility((prev) => !prev)}*/}
      {/*>*/}
      {/*  <Typography>*/}
      {/*    {dateRange ? showDateRange(dateRange) : 'Filter By Date'}*/}
      {/*  </Typography>*/}
      {/*</div>*/}
      <Modal open={visibility} onClose={() => setVisibility(false)}>
        <div
          style={{
            backgroundColor: 'white',
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            display: 'flex',
            flexDirection: 'column',
            borderRadius: '4px',
            overflow: 'hidden',
          }}
          color={colors.primary}
        >
          <DateRangePicker
            ranges={[selectionRange]}
            rangeColors={[colors.primary]}
            moveRangeOnFirstSelection
            onChange={handleDateSelect}
          />
          <Stack flexDirection='row' sx={{ p: 2, pt: 0 }}>
            <Button
              sx={{ flexGrow: 1 }}
              variant='outlined'
              size='large'
              onClick={() => {
                setVisibility(false);
                dispatch(setDateRange(null));
              }}
              color='warning'
            >
              Cancel
            </Button>
            <div style={{ width: '2rem' }} />
            <Button
              sx={{ flexGrow: 1 }}
              variant='contained'
              size='large'
              onClick={() => {
                setVisibility(false);
                dispatch(setDateRange(selectionRange));
              }}
              color='primary'
            >
              Filter
            </Button>
          </Stack>
        </div>
      </Modal>
    </>
  );
};

export default SelectDate;
