import React, { useEffect, useMemo, useState } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Modal,
  Stack,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NavItem from './NavItem';
import { connect } from 'react-redux';
import WeekCalendar from '../../report/WeekCalender';
import { fetchAllInvoices } from '../../redux/actions/invoiceActions';
import startOfWeek from 'date-fns/startOfWeek';
import { format, addMinutes } from 'date-fns';
import InvoicesList from '../common/InvoicesList';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  borderRadius: '8px',
};

export const initial = {
  name: 'Paid',
  color: 'primary.paid',
  status: 3,
};
function Invoice({ invoices, loading, fetchAllInvoices }) {
  const [open, setOpen] = useState(false);
  const [weekValue, setWeekValue] = React.useState(new Date());
  const [refValue, setRefValue] = React.useState('');
  const handleOpen = () => setOpen(true);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const handleClose = () => setOpen(false);

  const currentDate = useMemo(
    () =>
      format(
        addMinutes(
          startOfWeek(new Date()),
          startOfWeek(new Date()).getTimezoneOffset()
        ),
        'yyyy-MM-dd'
      ),
    []
  );

  useEffect(() => {
    const startDate = format(
      addMinutes(
        startOfWeek(weekValue),
        startOfWeek(weekValue).getTimezoneOffset()
      ),
      'yyyy-MM-dd'
    );

    if (currentDate === startDate) {
      fetchAllInvoices({
        published: true,
        id: refValue ? refValue : null,
      });
    } else {
      fetchAllInvoices({
        published: true,
        id: refValue ? refValue : null,
        startDate,
      });
    }
  }, [weekValue, refValue]);

  return (
    <Container
      maxWidth='md'
      sx={{
        pt: 2,
      }}
    >
      <Box
        sx={{
          borderBottom: 2,
          borderColor: 'primary.lineColor',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          py: 1,
          mb: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            px: 1,
          }}
          onClick={handleOpen}
        >
          <Typography
            variant='h5'
            color='text.primary'
            sx={{
              fontWeight: 'bold',
              pr: 2,
            }}
          >
            Week
          </Typography>
          <Box
            sx={{
              border: 2,
              borderColor: 'grey.600',
              borderRadius: '5px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              ml: 2,
              pr: 1,
              py: 0.5,
              cursor: 'pointer',
            }}
          >
            <Typography variant='h6' color='grey.400' sx={{ px: 1 }}>
              {selectedWeek ? `${selectedWeek?.getWeek()} week` : 'Select'}
            </Typography>
          </Box>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <WeekCalendar value={selectedWeek} setValue={setSelectedWeek} />
            <Stack flexDirection='row' sx={{ pl: 2, pr: 2 }}>
              <Button
                sx={{ flexGrow: 1 }}
                variant='outlined'
                size='large'
                onClick={() => {
                  setOpen(false);
                  setWeekValue(new Date());
                  setSelectedWeek(null);
                }}
                color='warning'
              >
                Cancel
              </Button>
              <div style={{ width: '2rem' }} />
              <Button
                sx={{ flexGrow: 1 }}
                variant='contained'
                size='large'
                onClick={() => {
                  setOpen(false);
                  if (selectedWeek) {
                    setWeekValue(selectedWeek);
                  }
                }}
                color='primary'
              >
                Save
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Box>
          <Typography
            variant='h5'
            color='text.primary'
            sx={{ fontWeight: 'bold' }}
          >
            invoices
          </Typography>
        </Box>
      </Box>
      <NavItem />
      <TextField
        fullWidth
        id='outlined-basic'
        label=''
        color='success'
        variant='outlined'
        placeholder='Search Invoice Ref'
        value={refValue}
        onChange={(event) => setRefValue(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ mt: 3, mb: 4 }}
      />

      <InvoicesList invoices={invoices} loading={loading} />
    </Container>
  );
}

const mapStateToProps = ({ invoice: { invoices, loading } }) => ({
  invoices,
  loading,
});

export default connect(mapStateToProps, { fetchAllInvoices })(Invoice);
