import React from 'react'
import {Box,Container,Button,Grid } from '@mui/material';
import PageHeader from '../../components/common/PageHeader';
//import TextInput from '../../components/clients/SetupClient/textInput';
import "./Page.css";

import TextEditor from './TextEditor';

const PrivacyPolicy = () => {
  return (
    <div>
     <Container className = "container" maxWidth='md'>
     <PageHeader title='Privacy Policy' link='/setting' />
           <div className="editor">
          <div className='editor'>
           <TextEditor/>
           </div>
       <Button  variant="contained" href="#contained-buttons">
      Update
     </Button>
     </div>
     
     </Container>
    </div>
  )
}

export default PrivacyPolicy