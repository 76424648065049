import React from "react";
import { Box, Typography } from "@mui/material";

const SubHeading = ({ title }) => {
  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: "success.light",
        display: "flex",
        justifyContent: "right",
        alignItems: "center",
        pb: 1,
        my: 2,
      }}
    >
      <Typography variant="h5" color="text.primary" sx={{ fontWeight: "bold" }}>
        {title}
      </Typography>
    </Box>
  );
};

export default SubHeading;
