import { INVOICE } from '../types';

const initialState = {
  loading: true,
  invoices: [],
  invoiceDetails: {
    loading: true,
    data: null,
    bankAccount: {
      account_holder_name: null,
      bank_name: null,
      routing_number: null,
      last4: null
    }
  },
};

const invoicesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case INVOICE.LOADING_START:
      return {
        ...state,
        invoices: [],
        loading: true,
      };
    case INVOICE.FETCH_SUCCESS:
      return {
        ...state,
        invoices: payload,
        loading: false,
      };
    case INVOICE.FETCH_FAILED:
      return {
        ...state,
        invoices: [],
        loading: false,
      };
    case INVOICE.FETCH_INVOICE_DETAILS:
      return {
        ...state,
        invoiceDetails: {
          loading: false,
          data: payload,
        },
      };
    case INVOICE.FETCH_INVOICE_DETAILS_START:
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          loading: true,
        },
      };
    case INVOICE.FETCH_INVOICE_DETAILS_FAILED:
      return {
        ...state,
        invoiceDetails: initialState.invoiceDetails,
      };
    case INVOICE.FETCH_INVOICE_BANK_ACCOUNT:
      return {
        ...state,
        invoiceDetails: {
          ...state.invoiceDetails,
          bankAccount: payload
        },
      };

    default: {
      return state;
    }
  }
};

export default invoicesReducer;
