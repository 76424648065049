import { useState, useEffect } from 'react';
import Nav from '../../components/navbar/nav';
import ClientCard from '../../components/card/ClientCard';
import {
  Container,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { connect } from 'react-redux';
import LoadingFull from '../../components/common/LoadingFull';
import { getAllClients } from '../../redux/actions/clientActions';

const ClientList = ({ clients, loading, getAllClients }) => {
  const [clientName, setClientName] = useState('');

  useEffect(() => {
    getAllClients({ name: clientName ? '^' + clientName : null });
  }, [clientName]);
  if (loading) {
    return <LoadingFull />;
  }
  return (
    <Container maxWidth='md'>
      <Nav type='client' link='/client/create' />
      <TextField
        fullWidth
        id='outlined-basic'
        label=''
        color='success'
        variant='outlined'
        placeholder='Search'
        value={clientName}
        onChange={(event) => setClientName(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ mt: 3, mb: 3 }}
      />
      <Grid container spacing={2}>
        {clients.map((client) => (
          <ClientCard item={client} type='client' />
        ))}
      </Grid>
    </Container>
  );
};

const mapStateToProps = ({ client: { clients, loading } }) => ({
  clients,
  loading,
});

export default connect(mapStateToProps, { getAllClients })(ClientList);
