import { Box, Card, Container } from '@mui/material';
import React, { useEffect } from 'react';
import { BsDownload } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { downloadAsExcel, filterByDate } from '../utils/utils';
import PageHeader from '../components/common/PageHeader';
import styles from './CarerTable.module.css';
import { fetchCarerCLientReports } from '../redux/actions/carerClientReportsActions';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableContainer from '@mui/material/TableContainer';
import SelectDate from '../components/common/SelectDate';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
};
const CarerReport = () => {
  const { reports, loading, dateRange } = useSelector(
    ({ clientActivityReports }) => clientActivityReports
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCarerCLientReports());
  }, []);

  return (
    <Container maxWidth='md'>
      <PageHeader title='carer Report' link='/report' />

      <Box
        sx={{
          display: 'flex',
          mb: 2,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <SelectDate />
        <BsDownload
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={() => downloadAsExcel(reports)}
          className={styles.footerButton}
        />
      </Box>

      <TableContainer component={Paper} className={styles.shown}>
        <table>
          <thead>
            <tr
              style={{
                backgroundColor: 'rgb(94,137,60)',
                border: '1px solid black',
                width: 'max-content',
              }}
            >
              {/* <div> */}
              <td className={styles.header} id={styles.clientName}>
                {/* className={styles.header} id={styles.clientName} */}
                <Box
                  sx={{
                    display: 'flex',
                    position: 'relative',
                    top: '4px',
                    width: '121px',
                  }}
                >
                  carer Name
                  {/*<Box sx={{ position: 'relative', left: '70px' }}>*/}
                  {/*  <ExpandMoreIcon />{' '}*/}
                  {/*</Box>*/}
                </Box>
              </td>{' '}
              <td className={styles.header} id={styles.clientName}>
                {/* id={styles.clientName} */}
                <Box
                  sx={{
                    display: 'flex',
                    position: 'relative',
                    top: '4px',
                    width: '121px',
                  }}
                >
                  client Name
                  {/*<Box sx={{ position: 'relative', left: '70px' }}>*/}
                  {/*  <ExpandMoreIcon />{' '}*/}
                  {/*</Box>*/}
                </Box>
              </td>{' '}
              <td className={styles.header}>
                {' '}
                <Box sx={{ width: 'max-content' }}>Week No. </Box>
              </td>
              <td className={styles.header}>
                <Box sx={{ width: 'max-content' }}>Start date </Box>
              </td>
              <td className={styles.header}>
                {' '}
                <Box sx={{ width: 'max-content' }}>End Date </Box>
              </td>
              <td className={styles.header}>
                {' '}
                <Box sx={{ width: 'max-content' }}>Care Type</Box>
              </td>
              <td className={styles.header}>
                {' '}
                <Box sx={{ width: 'max-content' }}>Hours</Box>
              </td>
              <td className={styles.header}>
                {' '}
                <Box>Location</Box>
              </td>
              <td className={styles.header}>
                {' '}
                <Box sx={{ width: 'max-content' }}>Net carer Income</Box>
              </td>
              <td className={styles.header}>
                {' '}
                <Box sx={{ width: 'max-content' }}>seakr Fee</Box>
              </td>
              <td className={styles.header}>
                {' '}
                <Box sx={{ width: 'max-content' }}>Total Revenue</Box>
              </td>
              <td className={styles.header}>
                <Box sx={{ width: 'max-content' }}>Late Submission</Box>
              </td>
              <td className={styles.header}>
                <Box>Status</Box>
              </td>
            </tr>
          </thead>
          <tbody>
            {reports ? (
              filterByDate(reports, dateRange).map((row) => (
                <tr
                  key={row.DateCreated}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <td>{row.CarerName}</td>
                  <td>{row.ClientName}</td>
                  <td>{row.WeekNum}</td>
                  <td>{row.StartDate}</td>
                  <td>{row.EndDate}</td>
                  <td>{row.CareType}</td>
                  <td>{row.Hours}</td>
                  <td>{row.Location}</td>
                  <td>{row.NetCarerIncome}</td>
                  <td>{row.SeakrFee}</td>
                  <td>{row.TotalRevenue}</td>
                  <td>{row.LateSubmission} Days</td>
                  <td>{row.Status}</td>
                </tr>
              ))
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // border: "2px solid green",
                  width: '325%',
                }}
              >
                <Card
                  style={{
                    margin: 'auto',
                    paddingTop: '4rem',
                    paddingBottom: '4rem',
                    textAlign: 'center',
                    // border: "2px solid black",
                    fontSize: '2rem',
                  }}
                >
                  No invoices yet!
                </Card>
              </div>
            )}
          </tbody>
        </table>
      </TableContainer>
    </Container>
  );
};

export default CarerReport;
