import React, { useState } from 'react';
import {
  Container,
  FormControl,
  RadioGroup,
  Radio,
  Typography,
  FormControlLabel,
  Box,
  Button,
} from '@mui/material';
import { toast } from 'react-toastify';
import { patchCarer } from '../../../redux/actions/carerActions';
import { connect } from 'react-redux';

const CarerStatus = ({
  id,
  patchCarer,
  carerStatus,
  handleClose,
  disabled = false,
}) => {
  const [status, setStatus] = useState(carerStatus);
  return (
    <Container>
      <Typography
        variant='h6'
        color='primary.profile'
        sx={{
          fontWeight: 'bold',
          mt: 2,
          mb: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Status Change
      </Typography>
      <FormControl
        component='fieldset'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RadioGroup
          aria-label='Status'
          defaultValue='Active'
          name='radio-buttons-group'
        >
          <Box
            sx={{
              border: 1,
              borderColor: 'grey.400',
              px: 2,
              py: 1,
              mb: 1,
              width: '100%',
            }}
          >
            <FormControlLabel
              value={1}
              checked={status === 1}
              control={<Radio />}
              onChange={(event) => setStatus(1)}
              label='Active'
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                m: 'auto',
              }}
            />
          </Box>
          <Box
            sx={{
              border: 1,
              borderColor: 'grey.400',
              px: 2,
              py: 1,
              mb: 1,
            }}
          >
            <FormControlLabel
              value={2}
              checked={status === 2}
              onChange={(event) => setStatus(2)}
              control={<Radio />}
              label='Inactive'
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                m: 'auto',
              }}
            />
          </Box>
          <Button
            variant='contained'
            size='large'
            color='primary'
            onClick={() => {
              patchCarer(id, { status }, (success, msg) => {
                if (success) {
                  toast.success('Status updated successfully');
                  handleClose();
                } else {
                  toast(msg);
                }
              });
            }}
            disabled={disabled}
            sx={{ my: 2 }}
          >
            Finish
          </Button>
        </RadioGroup>
      </FormControl>
    </Container>
  );
};

export default connect(() => {}, { patchCarer })(CarerStatus);
