import React, { useEffect } from 'react';
import styles from '../../components/clients/ClientDetail.module.css';
import { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import Nav from '../../components/invoice/NavItem';
// import Card from '../invoice/invoicecard';
import { RiErrorWarningFill } from 'react-icons/ri';
import { FaUserEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Box,
  Grid,
  Modal,
  useTheme,
  useMediaQuery,
} from '@mui/material';

import StatusDialog from '../../components/clients/StatusDialog';
import PageHeader from '../../components/common/PageHeader';
import SubHeading from '../../components/common/SubHeading';
import { connect } from 'react-redux';
import LoadingFull from '../../components/common/LoadingFull';
import ProfileDetail from '../../components/common/ProfileDetail';
import { getCarerDetails } from '../../redux/actions/carerActions';
import { getClientDetails } from '../../redux/actions/clientActions';
import InvoicesList from '../../components/common/InvoicesList';
import { initial } from '../../components/invoice/invoice';
import ContactDetail from '../../components/common/ContactDetail';
import { useSelector } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const ClientDetail = ({
  loading,
  clientDetails,
  getCarerDetails,
  getClientDetails,
  match,
}) => {
  const handleItemClick = (carer) => {
    getCarerDetails(carer.carer.id);
  };
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [type, setType] = useState(initial);
  const [open, setOpen] = React.useState(false);
  const { user } = useSelector(({ auth }) => auth);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getClientDetails(match.params.id);
  }, []);

  if (loading) {
    return <LoadingFull />;
  }

  return (
    <Container maxWidth='md'>
      <PageHeader title='client Details' link='/client' />
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            mb: 2,
          }}
        >
          <ProfileDetail
            type='client'
            name={clientDetails?.user.name}
            address={
              clientDetails?.careAddress.town +
              ', ' +
              clientDetails?.careAddress.country
            }
          />
        </Grid>
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={5}>
          <ContactDetail
            number={clientDetails.payerPhone}
            email={clientDetails?.user.email}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'flex',
          mt: 4,
        }}
        style={isSmall ? { justifyContent: 'center' } : {}}
      >
        {user.superUser && (
          <Link to={`/client/${clientDetails.id}/update`}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mr: 3,
              }}
            >
              <FaUserEdit className={styles.footerButton} />
              Edit Details
            </Box>
          </Link>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mr: 3,
          }}
          onClick={handleOpen}
        >
          <RiErrorWarningFill className={styles.footerButton} />
          Status
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <StatusDialog
              id={clientDetails.id}
              clientStatus={clientDetails.status.toString()}
              handleClose={handleClose}
              // disabled={!user.superUser}
            />
          </Box>
        </Modal>
      </Box>
      <SubHeading title='Assigned carer' />
      <Grid container spacing={4}>
        {clientDetails?.clientCarers.map((carer) => (
          <Grid item xs={12} key={carer.id} md={6}>
            <Link
              to={user.superUser ? `/client/${carer.id}/assignedcarer` : '#'}
              onClick={() => handleItemClick(carer)}
            >
              <Typography
                variant='body 1'
                color='text.primary'
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  border: 1,
                  borderColor: 'grey.600',
                  py: 2,
                  px: 1,
                }}
              >
                <PersonIcon
                  fontSize='large'
                  sx={{ color: 'primary.profile' }}
                />
                {carer.carer.user.name}
              </Typography>
            </Link>
          </Grid>
        ))}
      </Grid>
      <SubHeading title='Submitted invoices' />
      <Nav setType={setType} />
      <InvoicesList invoices={clientDetails?.clientInvoices} type={type} />
    </Container>
  );
};

const mapStateToProps = ({
  client: {
    clientDetails: { data, loading },
  },
}) => ({
  loading,
  clientDetails: data,
});

export default connect(mapStateToProps, {
  getCarerDetails,
  getClientDetails,
})(ClientDetail);
