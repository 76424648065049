import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setType } from '../../redux/reducers/invoiceTabReducer';

const options = [
  {
    name: 'Pending',
    color: 'primary.pending',
    status: 3,
  },
  {
    name: 'Review',
    color: 'primary.review',
    status: 1,
  },
  {
    name: 'Approved',
    color: 'primary.approved',
    status: 2,
  },
  {
    name: 'Paid',
    color: 'primary.paid',
    status: 4,
  },
];

function Item() {
  const type = useSelector(({ invoiceTabReducer }) => invoiceTabReducer.type);
  const dispatch = useDispatch();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {options.map((item) => (
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          // style={{ borderLeft: '1px solid rgba(0,0,0,.2)' }}
          key={item.name}
          onClick={() => {
            dispatch(setType(item));
          }}
        >
          <Typography
            variant='body1'
            color={item.status === type.status ? 'grey.900' : 'grey.400'}
            sx={{ fontWeight: 'bold' }}
          >
            {item.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
export default Item;
