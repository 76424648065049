import React from 'react';
import './onboarding.css';

function OnBoarding() {
  return (

    <div className="thankyou-body">

         <div className="thankyou-logo"><img src='./public/logo.png'></img></div> 

        <div className="icon-animated icon-animated-tick" tabindex="-1" aria-hidden="true">
            <svg className="circle" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <circle cx="50" cy="50" r="50"/>
            </svg>

            <div className="tick">
                <svg className="tick-leg1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 52">
                    <polygon className="" points="1,41 0,48 25,52 25,45" />
                </svg>
                <svg className="tick-leg2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 52">
                    <polygon className="" points="18,45 25,47 25,0 18,0" />
                </svg>
            </div>
        </div>

        <h1 className="thankyou-heading">Success</h1>
        {/* <p className="thankyou-p">Thank You <br/> <a href="">Contact your Seakr</a></p> */}
        <h1 className="thankyou-heading redirecting">Your Profile has been verified.</h1>


    </div>
  );
}

export default OnBoarding;
