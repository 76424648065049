import { CircularProgress } from '@mui/material';
import React from 'react';

const LoadingFull = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </div>
  );
};

export default LoadingFull;
