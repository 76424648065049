import { Grid } from '@mui/material';
import React, { useMemo } from 'react';
import NoInvoicesFound from './NoInvoicesFound';
import Card from '../invoice/invoicecard';
import Loader from './Loader';
import { useSelector } from 'react-redux';

const InvoicesList = ({ invoices, loading }) => {
  const type = useSelector(({ invoiceTabReducer }) => invoiceTabReducer.type);

  const selectedInvoices = useMemo(() => {
    return invoices.filter((item) => item.status === type.status);
  }, [type.status, invoices?.length]);

  if (loading) {
    return <Loader />;
  }

  if (!selectedInvoices?.length) {
    return <NoInvoicesFound />;
  }

  return (
    <Grid container spacing={4}>
      {selectedInvoices.map((item, index) => (
        <Card key={item.id} item={item} />
      ))}
    </Grid>
  );
};

export default InvoicesList;
