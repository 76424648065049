import React from "react";
import { Box, Typography } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
const ContactDetail = ({ number, email }) => {
  return (
    <Box>
      <Box
        sx={{
          border: 2,
          borderColor: "grey.400",
          boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.25)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: 2,
            borderColor: "grey.600",
            py: 1,
          }}
        >
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ display: "flex", alignItems: "center", pl: 2, py: 1 }}
          >
            <CallIcon sx={{ color: "primary.profile", pr: 1 }} />
            {number}
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ mr: 2, py: 1 }}
          >
            Mobile
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            py: 1,
          }}
        >
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ display: "flex", alignItems: "center", pl: 2, py: 1 }}
          >
            <EmailIcon sx={{ color: "primary.profile", pr: 1 }} />
            {email}
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ mr: 2, py: 1 }}
          >
            Email
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactDetail;
