import React from 'react';
import { Box, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { colors } from '../../constants/colors';
const ProfileDetail = ({ type, name, address }) => {
  return (
    <Box
      sx={{
        border: 2,
        borderColor: 'grey.400',
        px: 2,
        py: 1,
        boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
      }}
    >
      <Box
        sx={{
          pb: 1,
        }}
      >
        <Typography
          variant='h6'
          color='text.primary'
          sx={{
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          {type} Details
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography
              variant='h6'
              color='grey.800'
              sx={{
                fontWight: 'bold',
              }}
            >
              {name}
            </Typography>
            <Typography variant='body1' color='text.primary'>
              {address}
            </Typography>
          </Box>
          <Box
            sx={{
              border: `2px solid ${colors.primary}`,
              borderRadius: '50%',
              width: '3.5rem',
              height: '3.5rem',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {type !== 'Client' && (
              <PersonIcon
                fontSize='large'
                sx={{ color: 'primary.profile', px: 1, py: 1 }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileDetail;
