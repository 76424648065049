import { Box, Button, Card, Container, Modal, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { BsDownload } from 'react-icons/bs';
import { IoToday } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../components/common/PageHeader';
import styles from './CarerTable.module.css';
import { fetchCarerOriginalInvoiceReports } from '../redux/actions/carerOriginalInvoiceReportsActions';
import { downloadAsExcel, filterByDate } from '../utils/utils';
import WeekCalendar from './WeekCalender';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import SelectDate from '../components/common/SelectDate';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
};

const CarerOriginalInvoiceReport = () => {
  const dispatch = useDispatch();
  const { reports, loading, dateRange } = useSelector(
    ({ clientActivityReports }) => clientActivityReports
  );
  useEffect(() => {
    dispatch(fetchCarerOriginalInvoiceReports());
  }, []);

  return (
    <Container maxWidth='md'>
      <PageHeader title='carer Original Invoice Report' link='/report' />

      <Box
        sx={{
          display: 'flex',
          mb: 2,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <SelectDate />
        <BsDownload
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={() => downloadAsExcel(reports)}
          className={styles.footerButton}
        />
      </Box>

      <div styles={{}}>
        {reports && (
          <TableContainer component={Paper} className={styles.shown}>
            <table>
              <thead>
                <tr
                  style={{
                    backgroundColor: 'rgb(94,137,60)',
                    border: '1px solid black',
                    width: 'max-content',
                  }}
                >
                  {/* <div> */}
                  <td className={styles.header}>
                    <Box sx={{ width: 'max-content' }}>Record Id</Box>
                  </td>{' '}
                  <td className={styles.header}>
                    <Box sx={{ width: 'max-content' }}>Date Created</Box>
                  </td>{' '}
                  <td className={styles.header}>
                    {' '}
                    <Box sx={{ width: 'max-content' }}>Status</Box>
                  </td>
                  <td className={styles.header}>
                    <Box sx={{ width: 'max-content' }}>Service</Box>
                  </td>
                  <td className={styles.header}>
                    {' '}
                    <Box sx={{ width: 'max-content' }}>
                      carer Original Invoice Ref
                    </Box>
                  </td>
                  <td className={styles.header}>
                    {' '}
                    <Box sx={{ width: 'max-content' }}>Week Start date</Box>
                  </td>
                  <td className={styles.header}>
                    {' '}
                    <Box sx={{ width: 'max-content' }}>carer Ref</Box>
                  </td>
                  <td className={styles.header}>
                    {' '}
                    <Box sx={{ width: 'max-content' }}>carer First Name</Box>
                  </td>
                  <td className={styles.header}>
                    {' '}
                    <Box sx={{ width: 'max-content' }}>carer Surname</Box>
                  </td>
                  <td className={styles.header}>
                    {' '}
                    <Box sx={{ width: 'max-content' }}>Client Ref</Box>
                  </td>
                  <td className={styles.header}>
                    {' '}
                    <Box sx={{ width: 'max-content' }}>Client First Name</Box>
                  </td>
                  <td className={styles.header}>
                    <Box sx={{ width: 'max-content' }}>Client Surname</Box>
                  </td>
                  <td className={styles.header}>
                    <Box>Gross Amount</Box>
                  </td>
                  <td className={styles.header}>
                    <Box>seakrFee Amount</Box>
                  </td>
                  <td className={styles.header}>
                    <Box>Total</Box>
                  </td>
                </tr>
              </thead>
              <tbody>
                {reports ? (
                  filterByDate(reports, dateRange).map((row) => (
                    <tr
                      key={row.RecordId}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <td>{row.RecordId}</td>
                      <td>{row.DateCreated?.slice(0, 10)}</td>
                      <td>{row.Status}</td>
                      <td>{row.Service}</td>
                      <td>{row.CarerOriginalInvoiceRef}</td>
                      <td>{row.StartDate?.slice(0, 10)}</td>
                      <td>{row.CarerRef}</td>
                      <td>{row.CarerFirstName}</td>
                      <td>{row.CarerLastName}</td>
                      <td>{row.ClientRef}</td>
                      <td>{row.ClientFirstName}</td>
                      <td>{row.ClientLastName}</td>
                      <td>{row.GrossAmount}</td>
                      <td>{row.SeakrFee}</td>
                      <td>{row.Total}</td>
                    </tr>
                  ))
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      // border: "2px solid green",
                      width: '325%',
                    }}
                  >
                    <Card
                      style={{
                        margin: 'auto',
                        paddingTop: '4rem',
                        paddingBottom: '4rem',
                        textAlign: 'center',
                        // border: "2px solid black",
                        fontSize: '2rem',
                      }}
                    >
                      No invoices yet!
                    </Card>
                  </div>
                )}
              </tbody>
            </table>
          </TableContainer>
        )}
      </div>
    </Container>
  );
};

export default CarerOriginalInvoiceReport;
