import React from 'react';
import { colors } from '../../constants/colors';

const items = [
  {
    title: 'Pending',
    color: colors.danger,
    type: 3,
  },
  {
    title: 'Review',
    color: colors.yellow,
    type: 1,
  },
  {
    title: 'Approved',
    color: colors.blue,
    type: 2,
  },
  {
    title: 'Paid',
    color: colors.success,
    type: 4,
  },
];

const InvoiceTypeFlow = ({ status }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        paddingBottom: '1.5rem',
      }}
    >
      {items.map(({ color, title, type }, i) => (
        <>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <div
              style={{
                backgroundColor: type === status ? color : '#fff',
                border: `2px solid ${color}`,
                height: '8vw',
                width: '8vw',
                borderRadius: '50%',
                maxWidth: '48px',
                maxHeight: '48px',
                marginTop: '2.5rem',
                marginBottom: '2.5rem',
              }}
            />
            <p
              style={{
                position: 'absolute',
                bottom: '.75rem',
              }}
            >
              {title}
            </p>
          </div>
          {i !== 3 && (
            <div
              style={{
                height: '2px',
                width: '2rem',
                backgroundColor: 'rgba(0,0,0,.2)',
              }}
            />
          )}
        </>
      ))}
    </div>
  );
};

export default InvoiceTypeFlow;
