import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../constants';
import { getConfig } from '../../utils/utils';
import PageHeader from '../../components/common/PageHeader';
import { colors } from '../../constants/colors';

const SeakeHelpItem = ({ item }) => {
  const date = new Date(item.createdAt);

  return (
    <div
      style={{
        padding: '1rem',
        border: '2px solid grey',
        marginBottom: '1rem',
        borderRadius: '1rem',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <p style={{ fontSize: '1.2rem' }}>
          <span style={{ fontWeight: '500' }}>Subject: </span>
          {item.topic}
        </p>
        <p
          style={{ fontSize: '1rem', color: colors.primary }}
        >{`${date.toDateString()} ${date.toTimeString().split(' ')[0]}`}</p>
      </div>
      <div
        style={{
          height: '1px',
          width: '100%',
          backgroundColor: 'rgba(0,0,0,.2)',
          marginTop: '.8rem',
          marginBottom: '.8rem',
        }}
      />
      <p style={{ fontSize: '1.1rem' }}>{item.reason}</p>
    </div>
  );
};

const getSeakrHelpNotes = async () => {
  try {
    const res = await axios.get(`${API_URL}/api/support`, getConfig());
    return res.data.data;
  } catch (error) {
    return [];
  }
};

const SeakrHelpCenter = () => {
  const [items, setItems] = useState([]);

  const loadInitial = async () => {
    const data = await getSeakrHelpNotes();
    setItems(data);
  };

  useEffect(() => {
    loadInitial();
  }, []);
  console.log(items);
  return (
    <div style={{ padding: '1.3rem', paddingTop: '.2rem' }}>
      <PageHeader title='seakr Help Center' link='/' />

      {items.map((item) => (
        <SeakeHelpItem item={item} key={item.id} />
      ))}
    </div>
  );
};

export default SeakrHelpCenter;
