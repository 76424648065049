import axios from "axios";
import { API_URL } from "../../constants";
import { getConfig, handleErrors } from "../../utils/utils";
import { CARER_ORIGINAL_INVOICE_REPORTS } from "../types";

export const fetchCarerOriginalInvoiceReports = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(`${API_URL}/api/reports/carer-original-invoice`, {
      ...getConfig(),
    });
    // console.log(res.data);
    localStorage.setItem('carerOriginalInvoiceReports', JSON.stringify(res.data));
    const payload = res.data.data;
    dispatch({ type: CARER_ORIGINAL_INVOICE_REPORTS.FETCH_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: CARER_ORIGINAL_INVOICE_REPORTS.FETCH_FAILED });

    handleErrors(error);
  }
};
