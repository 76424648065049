import React from 'react';
import { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  Grid,
  Button,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TextInput from '../../components/clients/SetupClient/textInput';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageHeader from '../../components/common/PageHeader';
import { connect } from 'react-redux';

const initialDetails = {
  firstName: 'John',
  lastName: 'Doe',
  email: 'johndoe@gmail.com',
  newPassword: '123456789',
  currentPassword: '4585568455',
  confirmNewPassword: '4585568455',
};

const AccountSetting = ({ loading, user }) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [accDetails, setAccDetails] = useState(initialDetails);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAccDetails({
      ...accDetails,
      [name]: value,
    });
  };
  return (
    <Container maxWidth='md'>
      <PageHeader title='Account Setting' link='/setting' />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box>
            <TextInput
              heading='First Name'
              name='firstName'
              value='Finance seakr'
              disabled
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box>
            <TextInput
              heading='Email'
              name='email'
              value={user.email}
              color='red'
              disabled
              onChange={handleInputChange}
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          my: 2,
        }}
      >
        <Typography
          variant='h5'
          color='text.primary'
          sx={{
            fontWeight: 'bold',
            borderBottom: 1,
            borderColor: 'primary.profile',
          }}
        >
          Change Password
        </Typography>
      </Box>
      <Grid container spacing={isSmall ? 0 : 30}>
        <Grid item xs={12} md={6}>
          <TextInput
            heading='New Password'
            name='newPassword'
            value={accDetails.newPassword}
            type='password'
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput
            heading='Current Password'
            name='currentPassword'
            value={accDetails.currentPassword}
            type='password'
            onChange={handleInputChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={isSmall ? 0 : 30}>
        <Grid item xs={12} md={6}>
          <TextInput
            heading='Confirm New Password'
            name='confirmNewPassword'
            value={accDetails.confirmNewPassword}
            type='password'
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant='contained'
            size='large'
            color='primary'
            sx={{ my: 5, width: '100%' }}
          >
            Save Changes
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = ({ auth: { loading, user } }) => ({
  user,
  loading,
});

export default connect(mapStateToProps, {})(AccountSetting);
