//
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styles from './app.module.css';
import AssignedCarer from './components/clients/AssignedCarer';
import Failed from './components/Failed/Failed';
import Invoice from './components/invoice/invoice';
import InvoiceDetail from './components/invoice/InvoiceDetail';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import OnBoarding from './components/Success/onboarding';
import CarerOriginalInvoiceReport from './report/CarerOriginalInvoiceReport';
import CarerReport from './report/CarerReport';
import ClientActivityReport from './report/ClientActivityReport';
import ClientReport from './report/ClientReport';
import Report from './report/Report';
import XeroInvoicingReport from './report/XeroInvoicingReport';
import AddClient from './view/carer/AddClient';
import {
  default as CarerCreate,
  default as CarerForm,
} from './view/carer/CarerCreate';
import CarerDetail from './view/carer/CarerDetail';
import CarerList from './view/carer/CarerList';
import CarerUpdate from './view/carer/CarerUpdate';
import CreateClient from './view/client/ClientCreate';
import ClientDetail from './view/client/ClientDetail';
import Client from './view/client/ClientList';
import ClientUpdate from './view/client/ClientUpdate';
import AccountSetting from './view/setting/AccountSetting';
import SeakrHelpCenter from './view/setting/SeakrHelpCenter';
import Setting from './view/setting/Setting';


const MainDisplay = () => {
  return (
    <div className={styles.shown}>
      <ToastContainer />
      <Switch>
        <Route exact path='/'>
          <Invoice />
        </Route>
        <Route path='/setting'>
          <Setting />
        </Route>
        <Route exact path='/stripe-failed'>
          <Failed/>
        </Route>
        <Route exact path='/stripe-success'>
          <OnBoarding/>
        </Route>
        <Route path='/account' component={AccountSetting} />
        <Route exact path='/carer' component={CarerList} />
        <Route exact path='/carer/create' component={CarerCreate} />
        <Route exact path='/carer/:id' component={CarerDetail} />
        <Route exact path='/carer/:id/addclient' component={AddClient} />
        <Route exact path='/carer/:id/update' component={CarerUpdate} />
        <Route exact path='/client' component={Client} />
        <Route exact path='/client/create' component={CreateClient} />
        <Route exact path='/client/:id' component={ClientDetail} />
        <Route exact path='/client/:id/update' component={ClientUpdate} />
        <Route exact path='/stripe/failed' component={Failed}/>
        <Route exact path='/stripe/success' component={OnBoarding}/>
        <Route exact path='/privacy-policy' component={PrivacyPolicy}/>
        <Route exact path='/seakr-help-center' component={SeakrHelpCenter} />
        <Route
          exact
          path='/client/:id/assignedcarer'
          component={AssignedCarer}
        />

        <Route path='/invoice-detail/:id' component={InvoiceDetail} />
        <Route path='/carerform'>
          <CarerForm />
        </Route>

        <Route path='/report/client'>
          <ClientReport />
        </Route>
        <Route path='/report/carer'>
          <CarerReport />
        </Route>
        <Route path='/report/client-activity'>
          <ClientActivityReport />
        </Route>
        <Route path='/report/carer-original-invoice'>
          <CarerOriginalInvoiceReport />
        </Route>
        <Route path='/report/xero-invoicing'>
          <XeroInvoicingReport />
        </Route>
        <Route path='/report'>
          <Report />
        </Route>
      </Switch>
    </div>
  );
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(MainDisplay);
