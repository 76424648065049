import { Box, Grid, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { Link } from 'react-router-dom';
import {
  getInvoiceHeaderBackground,
  getInvoiceHeaderType,
} from '../../utils/utils';
import { colors } from '../../constants/colors';

const getInvoiceBorderColor = (status = 0) => {
  return status === 1 ? colors.primary : colors.yellow;
};

function Card({ item }) {
  const { totalPayment, user, startDate } = item;

  return (
    <Grid item xs={12} md={6}>
      <Link to={'/invoice-detail/' + item.id}>
        <Box
          sx={{
            borderRadius: '7px',
          }}
          style={{
            boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
          }}
        >
          <Box sx={{ display: 'flex' }}>
            <Box
              bgcolor={getInvoiceHeaderBackground(item.status)}
              sx={{
                width: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '1.25rem',
              }}
              style={{
                borderTopLeftRadius: '5px',
              }}
            >
              <Typography
                variant='body2'
                color='text.secondary'
                sx={{
                  fontSize: '0.8rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {getInvoiceHeaderType(item.status)}
              </Typography>
            </Box>
            <Box
              bgcolor='primary.week'
              sx={{
                width: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '1.25rem',
              }}
              style={{
                borderTopRightRadius: '5px',
              }}
            >
              <Typography
                variant='body2'
                color='text.secondary'
                sx={{
                  fontSize: '0.8rem',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Week {new Date(startDate).getWeek()}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box sx={{ px: 2, py: 2 }}>
              <Box
                sx={{
                  borderRadius: '50%',
                  width: '3.5rem',
                  height: '3.5rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: `2px solid ${getInvoiceBorderColor(
                    item.user?.carer?.status
                  )}`,
                }}
                style={{
                  boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
                }}
              >
                <PersonIcon
                  fontSize='large'
                  sx={{ color: 'primary.profile', px: 1, py: 1 }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant='body2'
                  color='text.bold'
                  sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    fontWeight: 'bold',
                    mt: 1,
                  }}
                >
                  carer:&nbsp;
                </Typography>
                <Typography
                  variant='body2'
                  color='text.temp'
                  sx={{
                    mt: 1,
                  }}
                >
                  {user?.carer?.user?.name}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ px: 3 }}>
              <Typography
                variant='h6'
                color='initial'
                sx={{
                  display: 'flex',
                  justifyContent: 'right',
                  fontWeight: 'bold',
                }}
              >
                {user?.client?.user?.name}
              </Typography>
              <Typography
                variant='body2'
                color='grey.500'
                sx={{
                  display: 'flex',
                  justifyContent: 'right',
                }}
              >
                Hourly / Live In
              </Typography>
              <Typography
                variant='h5'
                color='text.money'
                sx={{
                  my: 1,
                  display: 'flex',
                  fontWeight: 'bolder',
                  justifyContent: 'right',
                }}
              >
                £ {totalPayment}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Link>
    </Grid>
  );
}

export default Card;
