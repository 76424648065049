import React, { useEffect, useState } from 'react';
import TextInput from '../../components/clients/SetupClient/textInput';
import { Box, Checkbox, CircularProgress, Typography } from '@mui/material';
import { Button, Container, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isEmpty, isEmail } from '../../utils/utils';
import { updateCarer, getCarerDetails } from '../../redux/actions/carerActions';
import { connect } from 'react-redux';
import PageHeader from '../../components/common/PageHeader';

const CarerUpdate = ({ getCarerDetails, updateCarer, match, carerDetails }) => {
  const history = useHistory();
  const initialValues = (res) => {
    return {
      userId: res.user.id,
      name: res.user.name,
      email: res.user.email,
      phone: res.phone,
      reference: res.reference,
      company: res.company,
      country: res.address.country,
      postCode: res.address.postCode,
      town: res.address.town,
      // bankName: res.bankAccount.bank_name,
      // sortCode: res.bankAccount.routing_number,
      // accountName: res.bankAccount.account_holder_name,
      // accountNumber: '********' + res.bankAccount.last4,
      // iban: res.bankAccount.metadata?.iban,
      // swift: res.bankAccount.metadata?.swift,
      // bic: res.bankAccount.metadata?.bic,
      // routingNumber: res.bankAccount.routing_number,
      bankName: '',
      sortCode: '108800',
      accountName: '',
      accountNumber: '00012345',
      iban: '',
      swift: '',
      bic: '',
      routingNumber: '108800',
    };
  };
  const [values, setValues] = useState(initialValues(carerDetails));
  const [modifyBankDetails, setBankDetails] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCarerDetails(match.params.id).then((res) => {
      setValues(initialValues(res));
    });
  }, []);

  // if (detailLoading) {
  //   return <LoadingFull />;
  // }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleUpdateCarer = (e) => {
    e.preventDefault();
    if (!isEmail(values.email)) {
      toast('Enter a valid email!');
      return;
    }
    if (modifyBankDetails && values.accountNumber.startsWith('*')) {
      toast('Please enter full bank account number!');
      return;
    }
    setLoading(true);
    updateCarer(match.params.id, values, modifyBankDetails, (success, msg) => {
      setLoading(false);
      if (success) {
        toast.success('Carer updated successfully');
        history.goBack();
        return;
      } else {
        toast(msg);
      }
    });
  };

  return (
    <Container maxWidth='md'>
      <PageHeader
        title='Edit carer Details'
        link={`/carer/${match.params.id}`}
      />
      <form onSubmit={handleUpdateCarer}>
        <Grid container>
          <Grid item xs={12} md={5}>
            <TextInput
              heading='Name'
              name='name'
              value={values.name}
              disabled
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={5}>
            <TextInput
              heading='carer Reference'
              name='reference'
              required
              value={values.reference}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={5}>
            <TextInput
              heading='Company Name'
              required
              name='company'
              value={values.company}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={5}>
            <TextInput
              heading='Contact Number'
              name='phone'
              required
              value={values.phone}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={5}>
            <TextInput
              heading='carer/Company Address'
              name='country'
              required
              value={values.country}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={5}>
            <TextInput
              heading=' Email'
              name='email'
              disabled
              value={values.email}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} md={5}>
            <TextInput
              heading='Post Code (carer/Company Address)'
              name='postCode'
              required
              value={values.postCode}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={0} md={2} />
          <Grid item xs={12} md={5}>
            <TextInput
              heading='Town (carer/Company Address)'
              name='town'
              required
              value={values.town}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'success.light',
            display: 'flex',
            justifyContent: 'right',
            alignItems: 'center',
            pb: 1,
            my: 2,
          }}
        >
          <Typography
            variant='h4'
            color='text.primary'
            sx={{ fontWeight: 'bold' }}
          >
            Bank Details
          </Typography>
          <Checkbox
            sx={{ color: 'black' }}
            value={modifyBankDetails}
            onChange={(event) => setBankDetails(!modifyBankDetails)}
          />
        </Box>
        <Grid
          container
          sx={{
            mt: 3,
            pb: 3,
            borderBottom: 3,
            borderColor: 'primary.profile',
          }}
        >
          <Grid item xs={12} md={5}>
            <TextInput
              heading='Name of Bank'
              name='bankName'
              value={values.bankName}
              onChange={handleInputChange}
              disabled={!modifyBankDetails}
            />
            <TextInput
              heading='Name on Account'
              name='accountName'
              value={values.accountName}
              onChange={handleInputChange}
              disabled={!modifyBankDetails}
            />
            <TextInput
              heading='Routing Number/IFSC number'
              name='routingNumber'
              value={values.routingNumber}
              onChange={handleInputChange}
              disabled={!modifyBankDetails}
            />
            <TextInput
              heading='Swift'
              name='swift'
              value={values.swift}
              onChange={handleInputChange}
              disabled={!modifyBankDetails}
            />
          </Grid>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={5}>
            <TextInput
              heading='Sort Code'
              name='sortCode'
              value={values.sortCode}
              onChange={handleInputChange}
              disabled={!modifyBankDetails}
            />
            <TextInput
              heading='Account Number'
              name='accountNumber'
              value={values.accountNumber}
              onChange={handleInputChange}
              disabled={!modifyBankDetails}
            />
            <TextInput
              heading='BIC'
              name='bic'
              value={values.bic}
              onChange={handleInputChange}
              disabled={!modifyBankDetails}
            />
            <TextInput
              heading='IBAN'
              name='iban'
              value={values.iban}
              onChange={handleInputChange}
              disabled={!modifyBankDetails}
            />
            <Button
              variant='contained'
              size='large'
              color='primary'
              type='submit'
              sx={{ my: 8, width: '100%' }}
            >
              {loading ? (
                <CircularProgress color='inherit' size={24} />
              ) : (
                'Update'
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

const mapStateToProps = ({
  carer: {
    carerDetails: { loading, data },
  },
}) => ({
  detailLoading: loading,
  carerDetails: data,
});

export default connect(mapStateToProps, { updateCarer, getCarerDetails })(
  CarerUpdate
);
