// import styles from "./clientprofile.module.css";
import PersonIcon from '@mui/icons-material/Person';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { colors } from '../../constants/colors';

const getCarerBorderColor = (status = 0) => {
  return status === 1 ? colors.primary : colors.yellow;
};

const CarerCard = ({ item, type = 'client', noLink }) => {
  const body = (
    <Box
      sx={{
        border: 2,
        borderColor: 'grey.400',
        borderRadius: '5px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 2,
        py: 2,
      }}
    >
      <Box
        sx={{
          borderRadius: '50%',
          width: '3.5rem',
          height: '3.5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ml: 1,
          border: 3,
          borderColor: getCarerBorderColor(item.status),
        }}
      >
        <PersonIcon
          fontSize='large'
          sx={{ color: 'primary.profile', px: 1, py: 1 }}
        />
      </Box>

      <Box>
        <Typography
          variant='h5'
          color='textprimary'
          sx={{
            fontWeight: 'bold',
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          {item.user.name}
        </Typography>
        <Typography
          variant='body2'
          color='textprimary'
          sx={{
            fontWeight: 'normal',
            display: 'flex',
            justifyContent: 'right',
          }}
        >
          {item.address.town}, {item.address.country}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        width: '90%',
      }}
      style={{ cursor: 'pointer' }}
    >
      {noLink ? (
        <div>{body}</div>
      ) : (
        <Link to={`/carer/${item.id}`}>{body}</Link>
      )}
    </Grid>
  );
};

CarerCard.propTypes = {
  item: PropTypes.object.isRequired,
};

export default CarerCard;
