import { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Button,
  TextField,
  Autocomplete,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import TextInput from '../../components/clients/SetupClient/textInput';
import SelectInput from '../../components/clients/SetupClient/selectInput';
import PageHeader from '../../components/common/PageHeader';
import SubHeading from '../../components/common/SubHeading';
import { getAllClients } from '../../redux/actions/clientActions';
import { createClientCarerConfig } from '../../redux/actions/carerActions';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import TextWithLoader from '../../components/common/TextWithLoader';

const AddClient = ({
  clients,
  createClientCarerConfig,
  getAllClients,
  match,
}) => {
  useEffect(() => {
    getAllClients();
  }, []);
  const initialValues = {
    clientId: clients.length ? clients[0].id : '',
    carerId: match.params.id,
    grossCarerFee: '',
    seakrFee: '',
    netCarerPayment: '',
    grossCarerTax: 20,
    seakrTax: 20,
    netTax: 0,
    holidayGrossCarerRates: '',
    holidaySeakrRates: '',
    holidaysNetCarerPayment: '',
    customGrossCarerFee: '',
    customSeakrFee: '',
    customNetCarerPayment: '',
    customRatesRule: '',
  };

  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [values, setValues] = useState(initialValues);

  const clientList = [];
  clients.forEach((client) => {
    clientList.push({ label: client.user.name, id: client.id });
  });
  const [selectedClient, setSelectedClient] = useState(clientList[0]);
  const handleClientChange = (newValue) => {
    setSelectedClient(newValue);
    if (newValue) {
      setValues({ ...values, clientId: newValue.id });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <Container maxWidth='md'>
      <PageHeader
        title='Assign New client'
        link={`/carer/${match.params.id}`}
      />

      <form
        id={'main-form'}
        onSubmit={(e) => {
          e.preventDefault();
          setLoading(true);
          createClientCarerConfig(values)
            .then(() => {
              setLoading(false);
              toast('Client assigned successfully');
              history.goBack();
            })
            .catch((error) => {
              setLoading(false);
              toast(error.response?.data?.errors?.message || error.message);
            });
        }}
      >
        <Autocomplete
          value={selectedClient}
          onChange={(event, newValue) => {
            handleClientChange(newValue);
          }}
          id='controllable-states-demo'
          options={clientList}
          sx={{ width: '100%' }}
          renderInput={(params) => (
            <TextField {...params} required>
              <PersonIcon />
            </TextField>
          )}
        />
        <SubHeading title='Payment' />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Gross carer Fee (client Fee)'
              name='grossCarerFee'
              value={values.grossCarerFee}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectInput
              heading='client Fee VAT %'
              name='grossCarerTax'
              options={[
                { text: '20% VAT', value: 20 },
                { text: 'NO VAT', value: 0 },
              ]}
              value={values.grossCarerTax}
              onChange={handleInputChange}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='seakr Fee (Platform Fees)'
              name='seakrFee'
              value={values.seakrFee}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='Platform Fee VAT %'
              name='seakrTax'
              value={values.seakrTax}
              onChange={handleInputChange}
              required
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Net carer Payment (carer Fee)'
              name='netCarerPayment'
              value={values.netCarerPayment}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='carer Fee VAT %'
              name='netTax'
              value={values.netTax}
              onChange={handleInputChange}
              required
            />
          </Grid>
        </Grid>

        <SubHeading title='Holidays Rate' />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Gross carer Fee'
              name='holidayGrossCarerRates'
              value={values.holidayGrossCarerRates}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='client Fee VAT %'
              name='grossCarerTax'
              value={values.grossCarerTax}
              onChange={handleInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='seakr Fee'
              name='holidaySeakrRates'
              value={values.holidaySeakrRates}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='Platform Fee VAT %'
              name='seakrTax'
              value={values.seakrTax}
              onChange={handleInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Net carer Payment'
              name='holidaysNetCarerPayment'
              value={values.holidaysNetCarerPayment}
              onChange={handleInputChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='carer Fee VAT %'
              name='netTax'
              value={values.netTax}
              onChange={handleInputChange}
              disabled
            />
          </Grid>
        </Grid>

        <SubHeading title='Custom Rates' />

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Gross carer Fee'
              name='customGrossCarerFee'
              value={values.customGrossCarerFee}
              onChange={handleInputChange}
              //required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='client Fee VAT %'
              name='grossCarerTax'
              value={values.grossCarerTax}
              onChange={handleInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='seakr Fee'
              name='customSeakrFee'
              value={values.customSeakrFee}
              onChange={handleInputChange}
              //required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='Platform Fee VAT %'
              name='seakrTax'
              value={values.seakrTax}
              onChange={handleInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading='Net carer Payment'
              name='customNetCarerPayment'
              value={values.customNetCarerPayment}
              onChange={handleInputChange}
              // required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              heading='carer Fee VAT %'
              name='netTax'
              value={values.netTax}
              onChange={handleInputChange}
              disabled
            />
          </Grid>

          <Grid item xs={12} md={6}></Grid>

          <Grid item xs={12} md={6}>
            <TextInput
              type={'number'}
              heading=' Miles Rate'
              name='customRatesRule'
              value={values.customRatesRule}
              onChange={handleInputChange}
              required
            />
            <Button
              variant='contained'
              size='large'
              color='primary'
              type={'submit'}
              sx={{ my: 8, width: '100%' }}
            >
              <TextWithLoader loading={loading} text='Assign client' />
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};
const mapStateToProps = ({ client: { clients, loading } }) => ({
  clients,
  loading,
});
export default connect(mapStateToProps, {
  getAllClients,
  createClientCarerConfig,
})(AddClient);
