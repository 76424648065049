import React, { useState } from 'react';
import {
  Container,
  FormControl,
  RadioGroup,
  Radio,
  Typography,
  FormControlLabel,
  Box,
  Button,
} from '@mui/material';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { patchClient } from '../../redux/actions/clientActions';

const StatusDialog = ({
  handleClose,
  clientStatus,
  id,
  patchClient,
  disabled = false,
}) => {
  const [status, setStatus] = useState(clientStatus);
  return (
    <Container>
      <Typography
        variant='h6'
        color='primary.profile'
        sx={{
          fontWeight: 'bold',
          mt: 2,
          mb: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Status Changed
      </Typography>
      <FormControl
        component='fieldset'
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <RadioGroup
          aria-label='Status'
          defaultValue={status}
          name='radio-buttons-group'
          onChange={(event) => setStatus(event.target.value)}
        >
          <Box
            sx={{
              border: 1,
              borderColor: 'grey.400',
              px: 2,
              py: 1,
              mb: 1,
              width: '100%',
            }}
          >
            <FormControlLabel
              value={3}
              control={<Radio />}
              label='Deceased'
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                m: 'auto',
              }}
            />
          </Box>
          <Box
            sx={{
              border: 1,
              borderColor: 'grey.400',
              px: 2,
              py: 1,
              mb: 1,
            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio />}
              label='Active'
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                m: 'auto',
              }}
            />
          </Box>
          <Box
            sx={{
              border: 1,
              borderColor: 'grey.400',
              px: 2,
              py: 1,
              mb: 1,
            }}
          >
            <FormControlLabel
              value={2}
              control={<Radio />}
              label='Inactive'
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                m: 'auto',
              }}
            />
          </Box>
          <Button
            variant='contained'
            size='large'
            disabled={disabled}
            color='primary'
            onClick={() => {
              patchClient(id, { status }, (success, msg) => {
                if (success) {
                  toast.success('Status updated successfully');
                  handleClose(status);
                } else {
                  toast(msg);
                }
              });
            }}
            sx={{ my: 2 }}
          >
            Finish
          </Button>
        </RadioGroup>
      </FormControl>
    </Container>
  );
};

export default connect(() => ({}), {
  patchClient,
})(StatusDialog);
