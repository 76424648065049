import React, { useEffect } from 'react';
import Expenses from './Expenses';
import InvoiceSummary from './InvoiceSummary';
import BasicTable from './Table';
import { Container, Box, Typography, Grid, Stack } from '@mui/material';
import { IoToday } from 'react-icons/io5';
import PersonIcon from '@mui/icons-material/Person';
import { connect } from 'react-redux';
import LoadingFull from '../common/LoadingFull';
import PageHeader from '../common/PageHeader';
import SubHeading from '../common/SubHeading';
import {
  fetchInvoiceDetails,
  getCarerAccountDetails,
} from '../../redux/actions/invoiceActions';
import { monthNames } from '../../utils/utils';
import InvoiceTypeFlow from '../common/InvoiceTypeFlow';

const InvoiceDetail = ({
  loading,
  invoiceDetail,
  setInvoiceDetails,
  getCarerAccountDetails,
  bankAccount,
  match,
}) => {
  useEffect(() => {
    setInvoiceDetails(match.params.id).then((res) =>
      getCarerAccountDetails(res.user.carer.id)
    );
  }, []);

  if (loading) {
    return <LoadingFull />;
  }

  const start = new Date(invoiceDetail.startDate);
  const end = new Date(invoiceDetail.endDate);

  console.log(invoiceDetail.refundData);

  return (
    <Container maxWidth='md'>
      <PageHeader title='invoice Details' link='/' />

      <Grid container>
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              border: 1,
              borderColor: 'grey.400',
              borderRadius: '7px',
              my: 2,
              boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <Box
                bgcolor={
                  'primary.' +
                  { 2: 'review', 1: 'approved', 4: 'paid', 3: 'pending' }[
                    invoiceDetail.status
                  ]
                }
                color={'white'}
                sx={{
                  width: '40%',
                  mb: 0.2,
                  borderTopRightRadius: '5px',
                }}
              >
                <Typography
                  variant='body2'
                  color='white'
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {
                    { 1: 'review', 2: 'approved', 4: 'paid', 3: 'pending' }[
                      invoiceDetail.status
                    ]
                  }
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ px: 2, py: 2 }}>
                <Box
                  sx={{
                    border: 1,
                    borderColor: 'grey.300',
                    borderRadius: '50%',
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <PersonIcon
                    fontSize='large'
                    sx={{ color: 'primary.profile', px: 6, py: 2 }}
                  />
                </Box>
              </Box>
              <Box sx={{ px: 3 }}>
                <Typography
                  variant='h6'
                  color='initial'
                  sx={{
                    display: 'flex',
                    justifyContent: 'right',
                  }}
                >
                  {invoiceDetail.user.client.user.name}
                </Typography>
                <Typography
                  variant='body2'
                  color='initial'
                  sx={{
                    display: 'flex',
                    justifyContent: 'right',
                  }}
                >
                  {invoiceDetail.user.client.careAddress.town},{' '}
                  {invoiceDetail.user.client.careAddress.country}
                </Typography>
                <Typography
                  variant='h5'
                  color='text.money'
                  sx={{
                    my: 1,
                    display: 'flex',
                    fontWeight: 'bold',
                    justifyContent: 'right',
                  }}
                >
                  £ {invoiceDetail.totalPayment}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              border: 1,
              borderColor: 'grey.400',
              borderRadius: '5px',
              my: 2,
              px: 2,
              boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'left',
                mt: 1,
              }}
            >
              <Typography
                variant='h6'
                color='initial'
                sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Period
              </Typography>
            </Box>
            <Box
              sx={{
                border: 1,
                borderColor: 'grey.400',
                borderRadius: '5px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                my: 2,
                px: 2,
                py: 2,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  variant='body1'
                  color='initial'
                  sx={{
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {start.getDate()} {monthNames[start.getMonth()].substr(0, 3)}-
                  {end.getDate()} {monthNames[end.getMonth()].substr(0, 3)}
                </Typography>
                <Typography
                  variant='body2'
                  color='grey.600'
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  Week {new Date(start).getWeek()} -{' '}
                  {new Date(start).getFullYear()}
                </Typography>
              </Box>
              <IoToday size={25} color='rgb(159,203,105)' />
            </Box>
          </Box>
          {invoiceDetail?.refundData?.amount && (
            <Box
              sx={{
                border: 1,
                borderColor: 'grey.400',
                borderRadius: '7px',
                my: 2,
                p: 2,
                boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
              }}
            >
              <Stack
                flexDirection='row'
                justifyContent='space-between'
                flexGrow={1}
                mb={1}
              >
                <p>Refund amount</p>
                <Typography>{invoiceDetail.refundData.amount}</Typography>
              </Stack>
              <Stack
                flexDirection='row'
                justifyContent='space-between'
                flexGrow={1}
              >
                <p>Refund Note</p>
                <Typography>{invoiceDetail.refundData.note}</Typography>
              </Stack>
            </Box>
          )}
        </Grid>

        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              border: 1,
              borderColor: 'grey.400',
              borderRadius: '5px',
              my: 2,
              boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'right',
                mb: 0.2,
              }}
            >
              <Box
                sx={{
                  width: '40%',
                  px: 1,
                }}
              >
                <Typography
                  variant='body2'
                  color='grey.400'
                  sx={{
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'right',
                  }}
                >
                  carer Details
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ px: 1, py: 2 }}>
                <Box
                  sx={{
                    border: 1,
                    borderColor: 'grey.300',
                    borderRadius: '50%',
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <PersonIcon
                    fontSize='large'
                    sx={{ color: 'primary.profile', px: 6, py: 2 }}
                  />
                </Box>
              </Box>
              <Box sx={{ px: 1 }}>
                <Typography
                  variant='h6'
                  color='initial'
                  sx={{
                    display: 'flex',
                    justifyContent: 'right',
                  }}
                >
                  {invoiceDetail.user.carer.user.name}
                </Typography>
                <Typography
                  variant='body2'
                  color='initial'
                  sx={{
                    display: 'flex',
                    justifyContent: 'right',
                  }}
                >
                  {invoiceDetail.user.carer.address.town},{' '}
                  {invoiceDetail.user.carer.address.country}
                </Typography>
                <Typography
                  variant='body2'
                  color='initial'
                  sx={{
                    display: 'flex',
                    justifyContent: 'right',
                  }}
                >
                  {invoiceDetail.user.carer.company}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              border: 1,
              borderColor: 'grey.400',
              borderRadius: '5px',
              my: 2,
              px: 2,
              boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
            }}
          >
            <InvoiceTypeFlow status={invoiceDetail.status} />
          </Box>
        </Grid>
      </Grid>

      <SubHeading title='invoices Details (Day to Day)' />
      <BasicTable data={invoiceDetail.serviceData} />
      <Expenses items={invoiceDetail?.extraExpenses} />
      <InvoiceSummary data={invoiceDetail} bankAccount={bankAccount} />
    </Container>
  );
};

const mapStateToProps = ({
  invoice: {
    invoiceDetails: { data, loading, bankAccount },
  },
}) => ({
  invoiceDetail: data,
  bankAccount,
  loading,
});

export default connect(mapStateToProps, {
  setInvoiceDetails: fetchInvoiceDetails,
  getCarerAccountDetails,
})(InvoiceDetail);
