import * as React from 'react';
import TableContainer from '@mui/material/TableContainer';
// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import styles from './Table.module.css';

export default function BasicTable({ data }) {
  const rows = [];
  data.forEach((item) => {
    rows.push({
      day: item.day.substr(0, 3).toUpperCase(),
      careType: item.careType ? 'Live In' : 'Hourly',
      startTime: item.startTime,
      endTime:
        (+item.startTime.split(':')[0] + +item.duration)
          .toString()
          .padStart(2, 0) + ':00',
      unitPrice: item.duration,
      VAT: item.VAT,
      dailyTotal: item.totalPayment,
      type: item.isCustomRates
        ? 'Custom'
        : item.isHoliday
        ? 'Holiday'
        : 'Normal',
    });
  });
  return (
    <TableContainer className={styles.shown}>
      <table>
        <tr
          style={{
            backgroundColor: 'rgb(94,137,60)',
          }}
        >
          <td className={styles.header} id={styles.day}>
            Day
          </td>
          <td className={styles.header}>Type of Care</td>
          <td className={styles.header}>Start Time</td>
          <td className={styles.header}>End Time</td>
          <td className={styles.header}>Type</td>
          <td className={styles.header}>Units</td>
          <td className={styles.header}>Unit Price</td>
          <td className={styles.header}>Total Amount</td>
        </tr>

        {rows.map((row) => (
          <tr
            key={row.day}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <td className={styles.days}>{row.day}</td>
            <td className={styles.data}>{row.careType}</td>
            <td className={styles.data}>{row.startTime}</td>
            <td className={styles.data}>{row.endTime}</td>
            <td className={styles.data}>{row.type}</td>
            <td className={styles.data}>{(+row.unitPrice).toFixed(2)}</td>
            <td className={styles.data}>
              £
              {+row.unitPrice === 0
                ? 0
                : (+row.dailyTotal / +row.unitPrice).toFixed(2)}
            </td>
            <td className={styles.data}>£{row.dailyTotal}</td>
          </tr>
        ))}
      </table>
    </TableContainer>
  );
}
