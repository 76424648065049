import React, { useState } from 'react';
import styles from './invoiceSummary.module.css';
import { Container, Box, Typography, Grid, Button, Modal } from '@mui/material';
import { BsDownload } from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { toast } from 'react-toastify';
import { sendInvoiceFeedback } from '../../redux/actions/invoiceActions';
import { monthNames } from '../../utils/utils';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TextWithLoader from '../common/TextWithLoader';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '8px',
};

const InvoiceSummary = ({ data, bankAccount, sendInvoiceFeedback }) => {
  const { carerPayment, seakrPayment, totalPayment, status, mileageCost } = data;
  const theme = useTheme();
  const history = useHistory();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState(data.feedbackNote);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const due = new Date(data.dueDate);
  const { user } = useSelector(({ auth }) => auth);
  console.log(data, 'data')

  const sendRejectFeedback = async () => {
    try {
      setLoading(true);
      await sendInvoiceFeedback(data.id, { status: 1, feedbackNote: feedback });
      handleClose();
      toast.success('Feedback Sent!');
      history.goBack();
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };

  const sendApproveFeedback = async () => {
    try {
      setBtnLoading(true);
      await sendInvoiceFeedback(data.id, {
        status: 2,
        feedbackNote: 'Invoice Approved',
      });
      handleClose();
      history.goBack();
      toast.success('Invoice approved!');
    } catch (e) {
      toast.error(e.message);
    } finally {
      setBtnLoading(false);
    }
  };

  return (
    <Container>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'grey.500',
          pb: 5,
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              borderRight: 1,
              borderColor: 'primary.profile',
            }}
            style={isSmall ? { border: 'none' } : {}}
          >
            <Box
              sx={{
                border: 2,
                borderColor: 'grey.400',
                px: 2,
                py: 1,
                width: '70%',
              }}
              style={isSmall ? { margin: 'auto' } : {}}
            >
              <Typography
                variant='h6'
                color='text.primary'
                sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  borderBottom: 1,
                  borderColor: 'primary.profile',
                  mb: 2,
                  pb: 2,
                }}
              >
                Invoice Summary
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderBottom: 1,
                  borderColor: 'grey.300',
                  pb: 1,
                  my: 1,
                }}
              >
                <Typography
                  variant='body1'
                  color='text.primary'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  Net carer Fees
                </Typography>
                <Typography
                  variant='body1'
                  color='text.primary'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  £ {carerPayment}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderBottom: 1,
                  borderColor: 'grey.300',
                  pb: 1,
                  my: 1,
                }}
              >
                <Typography
                  variant='body1'
                  color='text.primary'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  seakr Fees
                </Typography>
                <Typography
                  variant='body1'
                  color='text.primary'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  £ {seakrPayment}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderBottom: 1,
                  borderColor: 'grey.300',
                  pb: 1,
                  my: 1,
                }}
              >
                <Typography
                  variant='body1'
                  color='text.primary'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  Mileage Cost
                </Typography>
                <Typography
                  variant='body1'
                  color='text.primary'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  £ {mileageCost}
                </Typography>
              </Box>

              {data?.user?.tax?.grossCarerTax && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: 1,
                    borderColor: 'grey.300',
                    pb: 1,
                    my: 1,
                  }}
                >
                  <Typography
                    variant='body1'
                    color='text.primary'
                    sx={{
                      fontWeight: 'bold',
                      borderColor: 'grey.200',
                    }}
                  >
                    Client VAT
                  </Typography>
                  <Typography
                    variant='body1'
                    color='text.primary'
                    sx={{
                      fontWeight: 'bold',
                      borderColor: 'grey.200',
                    }}
                  >
                     {data?.user?.tax?.grossCarerTax}%
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderBottom: 1,
                  borderColor: 'grey.300',
                  pb: 1,
                }}
              >
                <Typography
                  variant='body1'
                  color='text.primary'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  Total
                </Typography>
                <Typography
                  variant='body1'
                  color='text.primary'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  £ {totalPayment}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                border: 2,
                borderColor: 'grey.400',
                mt: 4,
                px: 2,
                py: 1,
                width: '70%',
              }}
              style={
                isSmall
                  ? { margin: 'auto', marginBottom: '1rem', marginTop: '1rem' }
                  : {}
              }
            >
              <Typography
                variant='h6'
                color='text.primary'
                sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderBottom: 1,
                  borderColor: 'primary.profile',
                  mb: 2,
                  pb: 2,
                }}
              >
                Invoice Reference Id
              </Typography>
              <Typography
                variant='h6'
                color='text.primary'
                sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  wordBreak: 'break-all'
                }}
              >
                {data.id}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                border: 2,
                borderColor: 'grey.400',
                width: '70%',
                height: '225px',
                px: 2,
                py: 1,
              }}
            >
              <Typography
                variant='h6'
                color='text.primary'
                sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  mb: 2,
                  pb: 2,
                }}
              >
                Bank Details
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: 1,
                  borderColor: 'grey.400',
                  pb: 1,
                }}
              >
                <Typography
                  variant='body1'
                  color='primary.profile'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  Name
                </Typography>
                <Typography
                  variant='body2'
                  color='text.primary'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  {bankAccount?.account_holder_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: 1,
                  borderColor: 'grey.400',
                  py: 1,
                }}
              >
                <Typography
                  variant='body1'
                  color='primary.profile'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  Acc. No.
                </Typography>
                <Typography
                  variant='body2'
                  color='text.primary'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  ***{bankAccount?.last4}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: 1,
                  borderColor: 'grey.400',
                  py: 1,
                }}
              >
                <Typography
                  variant='body1'
                  color='primary.profile'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  Bank Code
                </Typography>
                <Typography
                  variant='body2'
                  color='text.primary'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  {bankAccount?.routing_number}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: 1,
                  borderColor: 'grey.400',
                  py: 1,
                }}
              >
                <Typography
                  variant='body1'
                  color='primary.profile'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  Bank Name
                </Typography>
                <Typography
                  variant='body2'
                  color='text.primary'
                  sx={{
                    fontWeight: 'bold',
                    borderColor: 'grey.200',
                  }}
                >
                  {bankAccount?.bank_name}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                border: 2,
                borderColor: 'grey.400',
                mt: 4,
                px: 2,
                py: 1,
                width: '70%',
              }}
            >
              <Typography
                variant='h6'
                color='primary.main'
                sx={{
                  fontWeight: 'bold',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: 1,
                }}
              >
                Expected Payment Date:
              </Typography>
              <Typography
                variant='h6'
                color='grey.600'
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                  mb: 1,
                }}
              >
                {due.getDate()} {monthNames[due.getMonth()]} {due.getFullYear()}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {user.superUser && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            my: 3,
          }}
        >
          {status === 3 && (
            <Button
              variant='contained'
              size='large'
              color='primary'
              sx={{ my: 4, px: 12 }}
              onClick={() => sendApproveFeedback()}
            >
              <TextWithLoader text='Approve Invoice' loading={btnLoading} />
            </Button>
          )}
          <Box
            sx={{
              display: 'flex',
            }}
          >
            {status === 4 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mr: 3,
                  cursor: 'pointer',
                }}
                onClick={() => {
                  window.location.href = data.pdfURL;
                }}
              >
                <BsDownload className={styles.footerButton} />
                Download
              </Box>
            )}
            {status === 3 && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mr: 3,
                }}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleOpen()}
              >
                <FaEdit className={styles.footerButton} />
                Send Back
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <TextareaAutosize
            value={feedback}
            onChange={(event) => setFeedback(event.target.value)}
            aria-label='empty textarea'
            placeholder='Empty'
            style={{
              width: 400,
              height: 150,
              borderRadius: '4px',
              padding: '4px',
            }}
          />
          <Button
            variant='contained'
            color='primary'
            sx={{ mt: 2 }}
            onClick={() => sendRejectFeedback()}
          >
            <TextWithLoader text='Send Feedback' loading={loading} />
          </Button>
        </Box>
      </Modal>
    </Container>
  );
};

export default connect(() => ({}), { sendInvoiceFeedback })(InvoiceSummary);
