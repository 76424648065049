// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClientDetail_footerButton__12QM6 {\n  font-size: 2rem;\n  border: solid 2px hsl(0, 13%, 95%);\n  padding: 1rem;\n  color: #567d37;\n  margin-bottom: 10px;\n}\n#ClientDetail_popup-root__1EQaL {\n  background-color: red;\n}\n", "",{"version":3,"sources":["webpack://src/components/clients/ClientDetail.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kCAAkC;EAClC,aAAa;EACb,cAAc;EACd,mBAAmB;AACrB;AACA;EACE,qBAAqB;AACvB","sourcesContent":[".footerButton {\n  font-size: 2rem;\n  border: solid 2px hsl(0, 13%, 95%);\n  padding: 1rem;\n  color: #567d37;\n  margin-bottom: 10px;\n}\n#popup-root {\n  background-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerButton": "ClientDetail_footerButton__12QM6",
	"popup-root": "ClientDetail_popup-root__1EQaL"
};
export default ___CSS_LOADER_EXPORT___;
