export const INVOICE = {
  LOADING_START: 'INVOICE-loading',
  FETCH_SUCCESS: 'INVOICE-fetch-success',
  FETCH_FAILED: 'INVOICE-fetch-failed',
  FETCH_INVOICE_DETAILS: 'get-invoice-details',
  FETCH_INVOICE_DETAILS_FAILED: 'get-invoice-details-failed',
  FETCH_INVOICE_DETAILS_START: 'get-invoice-details-start',
  FETCH_POOLS: 'fetch-pools',
  FETCH_INVOICE_BANK_ACCOUNT: 'get-invoice-bank-details',
};

export const CARER_CLIENT_REPORTS = {
  LOADING_START: 'REPORTS-loading',
  FETCH_SUCCESS: 'REPORTS-fetch-success',
  FETCH_FAILED: 'REPORTS-fetch-failed',
};

export const CARER_ORIGINAL_INVOICE_REPORTS = {
  LOADING_START: 'REPORTS-loading',

  FETCH_SUCCESS: 'REPORTS-fetch-success',
  FETCH_FAILED: 'REPORTS-fetch-failed',
};

export const CLIENT_ACTIVITY_REPORTS = {
  LOADING_START: 'REPORTS-loading',
  SET_DATE: 'REPORTS-set-date',

  FETCH_SUCCESS: 'REPORTS-fetch-success',
  FETCH_FAILED: 'REPORTS-fetch-failed',
};

export const XERO_INVOICING_REPORTS = {
  LOADING_START: 'REPORTS-loading',
  FETCH_SUCCESS: 'REPORTS-fetch-success',
  FETCH_FAILED: 'REPORTS-fetch-failed',
};

export const CLIENT = {
  LOADING_START: 'CLIENT-loading',
  FETCH_SUCCESS: 'CLIENT-fetch-success',
  FETCH_FAILED: 'CLIENT-fetch-failed',
  FETCH_CLIENT_DETAILS: 'get-CLIENT-details',
  FETCH_CLIENT_DETAILS_START: 'get-CLIENT-details-start',
  FETCH_CLIENT_DETAILS_FAIL: 'get-CLIENT-details-fail',
  ADD_CARER_IN_CLIENT: 'ADD_CARER_IN_CLIENT',
  FETCH_POOLS: 'fetch-pools',
  ADD_CLIENT: 'add-client',
  UPDATE_CLIENT: 'update-client',
  DELETE_CLIENT: 'delete-client',
};

export const CARER = {
  LOADING_START: 'CARER-loading',
  FETCH_SUCCESS: 'CARER-fetch-success',
  FETCH_FAILED: 'CARER-fetch-failed',
  FETCH_CARER_DETAILS: 'get-CARER-details',
  FETCH_CARER_DETAILS_START: 'get-CARER-details-start',
  FETCH_CARER_DETAILS_FAIL: 'get-CARER-details-fail',
  ADD_CLINT_IN_CARER: 'add-client-in-carer',
  FETCH_POOLS: 'fetch-pools',
  ADD_CARER: 'add-CARER',
  UPDATE_CARER: 'update-CARER',
  DELETE_CARER: 'delete-CARER',
};

export const USER = {
  LOADING_START: 'user-loading',
  FETCH_SUCCESS: 'user-fetch-success',
  FETCH_FAILED: 'user-failed',
  LOGOUT: 'logout',
};
