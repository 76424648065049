import React from "react";
import styles from "./app.module.css";
import { Link } from "react-router-dom";
import clientIcon from "./assests/clientIcon.svg";
import carerIcon from "./assests/carerIcon.svg";
import invoiceIcon from "./assests/invoiceIcon.svg";
import reportIcon from "./assests/reportIcon.svg";

import { useState } from "react";
const MainMenu = ({ sideBar, toggleDrawer }) => {
  const [data, setData] = useState(
    window.location.pathname.startsWith("/carer")
      ? 3
      : window.location.pathname.startsWith("/client")
      ? 2
      : 1
  );

  return (
    <div
      className={styles.menu}
      style={sideBar ? { display: "block" } : { display: "none" }} 
    >
      <Link to="/">
        <div
          className={styles.menuItem}
          onClick={() => {
            setData(1);
          }}
          style={
            data === 1
              ? { backgroundColor: "white" }
              : { backgroundColor: "inherit" }
          }
        >
          
          <img
          
            src={invoiceIcon}
            alt="invoice"
            className= {styles.button}
            style={
              data === 1
                ? {
                    filter:
                      "invert(53%) sepia(4%) saturate(6983%) hue-rotate(45deg) brightness(93%) contrast(90%)",
                  }
                : { backgroundColor: "inherit" }
               
            }
          />
          
          <div>
          <h2  
           className={styles.item}>invoices</h2>
          </div>
        </div>
      </Link>
      <Link to="/client">
        <div
          className={styles.menuItem}
          onClick={() => {
            setData(2);
          }}
          style={
            data === 2
              ? { backgroundColor: "white" }
              : { backgroundColor: "inherit" }
          }
        >
          
          <img
            src={clientIcon}
            alt="client"
            className={styles.button}
            style={
              data === 2
                ? {
                    filter:
                      "invert(53%) sepia(4%) saturate(6983%) hue-rotate(45deg) brightness(93%) contrast(90%)",
                  }
                : { backgroundColor: "inherit" }
            }
          />
         
          <h2 
           className={styles.item}>client</h2>
          
        </div>
      </Link>
      <Link to="/carer">
        <div
          className={styles.menuItem}
          onClick={() => {
            setData(3);
          }}
          style={
            data === 3
              ? { backgroundColor: "white" }
              : { backgroundColor: "inherit" }
          }
        >
         
          <img
            src={carerIcon}
            alt="carer"
            className={styles.button}
            style={
              data === 3
                ? {
                    filter:
                      "invert(53%) sepia(4%) saturate(6983%) hue-rotate(45deg) brightness(93%) contrast(90%)",
                  }
                : { backgroundColor: "inherit" }
            }
          />
         
          <h2 
          className={styles.item}>carer</h2>
        </div>
      </Link>
      <Link to="/report">
        <div
          className={styles.menuItem}
          onClick={() => {
            setData(4);
          }}
          style={
            data === 4
              ? { backgroundColor: "white" }
              : { backgroundColor: "inherit" }
          }
        >
          
          <img 
            src={reportIcon}
            alt="report"
            className={styles.button}
            style={
              data === 4
                ? {
                    filter:
                      "invert(53%) sepia(4%) saturate(6983%) hue-rotate(45deg) brightness(93%) contrast(90%)",
                  }
                : { backgroundColor: "inherit" }
            }
          />
          
          <h2 
          className={styles.item}>report</h2>
        </div>
      </Link>
    </div>
  );
};

export default MainMenu;
