// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".invoiceSummary_footerButton__3Y9In {\n  font-size: 2rem;\n  border: solid 2px hsl(0, 7%, 81%);\n  padding: 0.8rem;\n  color: #567d37;\n  border-radius: 4px;\n}\n\n.invoiceSummary_headercolor__2s6zs {\n  color: 'red';\n}\n", "",{"version":3,"sources":["webpack://src/components/invoice/invoiceSummary.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iCAAiC;EACjC,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".footerButton {\n  font-size: 2rem;\n  border: solid 2px hsl(0, 7%, 81%);\n  padding: 0.8rem;\n  color: #567d37;\n  border-radius: 4px;\n}\n\n.headercolor {\n  color: 'red';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerButton": "invoiceSummary_footerButton__3Y9In",
	"headercolor": "invoiceSummary_headercolor__2s6zs"
};
export default ___CSS_LOADER_EXPORT___;
