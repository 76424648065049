const initialState = {
  type: {
    name: 'Paid',
    color: 'primary.paid',
    status: 3,
  },
};

export const setType = (data) => (dispatch) => {
  dispatch({ type: 'SET_TYPE', payload: data });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'SET_TYPE':
      return { ...state, type: payload };

    default:
      return state;
  }
};
