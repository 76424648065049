import { CARER_ORIGINAL_INVOICE_REPORTS } from '../types';

const initialState = {
  loading: true,
  reports: [],
};

const carerOriginalInvoiceReportsReducers = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CARER_ORIGINAL_INVOICE_REPORTS.LOADING_START:
      return {
        ...state,
        reports: [],
        loading: true,
      };
    case CARER_ORIGINAL_INVOICE_REPORTS.FETCH_SUCCESS:
      return {
        ...state,
        reports: payload,
        loading: false,
      };
    case CARER_ORIGINAL_INVOICE_REPORTS.FETCH_FAILED:
      return {
        ...state,
        reports: [],
        loading: false,
      };

    default: {
      return state;
    }
  }
};

export default carerOriginalInvoiceReportsReducers;
