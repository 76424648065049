import React from 'react';
import {Box, Typography, Select, MenuItem} from '@mui/material';
import './textInput.css';
function SelectInput({
  value,
  name,
  onChange,
  heading,
  options = [],
  color,
  disabled = false,
  required = false,
}) {
  return (
    <Box
      border
      sx={{
        borderBottom: 1,
        borderColor: 'grey.200',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'right',
        my: 1,
        pb: 2,
      }}
    >
      <Typography variant='h6' color='text.primary' sx={{ fontWeight: 'bold' }}>
        {heading}
      </Typography>
      <Select
        className='inputBorderRadius'
        sx={{borderRadius: '15px'}}
        id='outlined-basic'
        color='success'
        label=''
        variant='outlined'
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={required}
        style={{ backgroundColor: { color } }}
      >
        {options.map(option => (
          <MenuItem value={option.value}>{option.text}</MenuItem>
        ))}
      </Select>
    </Box>
  );
}

export default SelectInput;
