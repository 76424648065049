import { Box, Button, Card, Container, Modal, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { BsDownload } from 'react-icons/bs';
import { IoToday } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import PageHeader from '../components/common/PageHeader';
import styles from './CarerTable.module.css';
import { fetchXeroInvoicingReports } from '../redux/actions/xeroInvoicingReportActions';
import { downloadAsExcel, filterByDate } from '../utils/utils';
import WeekCalendar from './WeekCalender';
import Paper from '@mui/material/Paper';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TableContainer from '@mui/material/TableContainer';
import SelectDate from '../components/common/SelectDate';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
  justifyContent: 'center',
};

const XeroInvoicingReport = () => {
  const { reports, loading, dateRange } = useSelector(
    ({ clientActivityReports }) => clientActivityReports
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchXeroInvoicingReports());
  }, []);

  return (
    <Container maxWidth='md'>
      <PageHeader title='Xero Invoicing Report' link='/report' />

      <Box
        sx={{
          display: 'flex',
          mb: 2,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <SelectDate />
        <BsDownload
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={() => downloadAsExcel(reports)}
          className={styles.footerButton}
        />
      </Box>

      {reports && (
        <TableContainer component={Paper} className={styles.shown}>
          <table>
            <thead>
              <tr
                style={{
                  backgroundColor: 'rgb(94,137,60)',
                  border: '1px solid black',
                  width: 'max-content',
                }}
              >
                {/* <div> */}
                <td className={styles.header} id={styles.clientName}>
                  {/* className={styles.header} id={styles.clientName} */}
                  <Box
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      top: '4px',
                      width: '121px',
                    }}
                  >
                    Invoice Date
                    {/*<Box sx={{ position: 'relative', left: '70px' }}>*/}
                    {/*  <ExpandMoreIcon />*/}
                    {/*</Box>*/}
                  </Box>
                </td>
                <td className={styles.header} id={styles.clientName}>
                  {/* id={styles.clientName} */}
                  <Box
                    sx={{
                      display: 'flex',
                      position: 'relative',
                      top: '4px',
                      width: '121px',
                    }}
                  >
                    Due Date
                    {/*<Box sx={{ position: 'relative', left: '70px' }}>*/}
                    {/*  <ExpandMoreIcon />*/}
                    {/*</Box>*/}
                  </Box>
                </td>
                <td className={styles.header}>
                  <Box sx={{ width: 'max-content' }}>Description</Box>
                </td>
                <td className={styles.header}>
                  <Box sx={{ width: 'max-content' }}>Quantity</Box>
                </td>
                <td className={styles.header}>
                  <Box sx={{ width: 'max-content' }}>Unit Amount</Box>
                </td>
                <td className={styles.header}>
                  <Box sx={{ width: 'max-content' }}>Account Code</Box>
                </td>
                <td className={styles.header}>
                  <Box sx={{ width: 'max-content' }}>Tax Type</Box>
                </td>
                <td className={styles.header}>
                  <Box>Branding Theme</Box>
                </td>
              </tr>
            </thead>

            <tbody>
              {reports ? (
                filterByDate(reports, dateRange).map((row, i) => (
                  <tr
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <td>{row.InvoiceDate?.slice(0, 10)}</td>
                    <td>{row.DueDate?.slice(0, 10)}</td>
                    <td>{row.Description}</td>
                    <td>{row.Quantity}</td>
                    <td>{row.UnitAmount}</td>
                    <td>{row.AccountCode}</td>
                    <td>{row.TaxType}</td>
                    <td>{row.BrandingTheme}</td>
                  </tr>
                ))
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    // border: "2px solid green",
                    width: '325%',
                  }}
                >
                  <Card
                    style={{
                      margin: 'auto',
                      paddingTop: '4rem',
                      paddingBottom: '4rem',
                      textAlign: 'center',
                      // border: "2px solid black",
                      fontSize: '2rem',
                    }}
                  >
                    No invoices yet!
                  </Card>
                </div>
              )}
            </tbody>
          </table>
        </TableContainer>
      )}
    </Container>
  );
};

export default XeroInvoicingReport;
