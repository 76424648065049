import { useState } from 'react';
import Nav from '../../components/navbar/nav';
import CarerCard from '../../components/card/CarerCard';
import {
  Container,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { connect } from 'react-redux';
import LoadingFull from '../../components/common/LoadingFull';
import { getAllCarers } from '../../redux/actions/carerActions';
import { useEffect } from 'react';

function CarerList({ carers, loading, getAllCarers }) {
  const [nameValue, setNameValue] = useState('');

  useEffect(() => {
    getAllCarers({ name: nameValue ? '^' + nameValue : null });
  }, [nameValue]);

  if (loading) {
    return <LoadingFull />;
  }
  return (
    <Container maxWidth='md'>
      <Nav type='carer' link='/carer/create' />
      <TextField
        fullWidth
        id='outlined-basic'
        label=''
        color='success'
        variant='outlined'
        placeholder='Search'
        value={nameValue}
        onChange={(event) => setNameValue(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ mt: 3, mb: 3 }}
      />
      <Grid container spacing={2}>
        {carers.map((carer) => (
          <CarerCard key={carer.id} item={carer} type='carer' />
        ))}
      </Grid>
    </Container>
  );
}

const mapStateToProps = ({ carer: { carers, loading } }) => ({
  carers,
  loading,
});

export default connect(mapStateToProps, { getAllCarers })(CarerList);
