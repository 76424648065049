import React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import './textInput.css';
function TextInput({
  value,
  name,
  onChange,
  heading,
  type,
  color,
  disabled = false,
  required = false,
}) {
  return (
    <Box
      border
      sx={{
        borderBottom: 1,
        borderColor: 'grey.200',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'right',
        my: 1,
        pb: 2,
      }}
    >
      <Typography variant='h6' color='text.primary' sx={{ fontWeight: 'bold' }}>
        {heading}
      </Typography>
      <TextField
        className='inputBorderRadius'
        id='outlined-basic'
        type={type}
        color='success'
        label=''
        variant='outlined'
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        required={required}
        style={{ backgroundColor: { color } }}
      />
    </Box>
  );
}

export default TextInput;
