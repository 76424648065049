import React, { useEffect, useState } from 'react';
import '../../components/clients/SetupClient/Form.css';
import RadioInput from '../../components/clients/SetupClient/Radioinput';
import PageHeader from '../../components/common/PageHeader';
import {
  Button,
  Container,
  Typography,
  Box,
  TextField,
  Grid,
  CircularProgress,
} from '@mui/material';
import TextInput from '../../components/clients/SetupClient/textInput';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  updateClient,
  getClientDetails,
} from '../../redux/actions/clientActions';
import { connect } from 'react-redux';
import { useFormik } from 'formik';

const UpdateClient = ({
  updateClient,
  getClientDetails,
  clientDetails,
  match,
}) => {
  const initialValues = (res) => {
    return {
      seakr: res.seakr,
      seakrClientRelation: res.seakrClientRelation,
      careAddress: {
        postCode: res.careAddress.postCode,
        country: res.careAddress.country,
        town: res.careAddress.town,
      },
      billingAddress: {
        postCode: res.billingAddress.postCode,
        country: res.billingAddress.country,
        town: res.billingAddress.town,
      },
      notes: res.notes,
      careType: res.careType,
      fundingType: res.fundingType,
      paymentMethod: res.paymentMethod,
      name: res.user?.name,
      email: res.user?.email,
      userId: res.user?.id,
      phone: res.phone,
      payerEmail: res.payerEmail,
      payerPhone: res.payerPhone,
    };
  };
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  console.log(clientDetails);

  const [values, setValues] = useState(initialValues(clientDetails));

  useEffect(() => {
    getClientDetails(match.params.id).then((res) => {
      setValues(initialValues(res));
    });
  }, []);

  const formik = useFormik({
    initialValues: values,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      updateClient(match.params.id, values, (success, msg) => {
        setLoading(false);
        if (success) {
          toast('Client updated successfully');
          history.goBack();
          return;
        } else {
          toast(msg);
        }
      });
    },
    //validate using formik
    validate: (values) => {
      //validate email
      const errors = {};
      if (!values.email) {
        errors.email = 'email is required';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = 'invalid email address';
      }
      if (!values.payerEmail) {
        errors.payerEmail = 'payer email is required';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.payerEmail)
      ) {
        errors.payerEmail = 'invalid payer email address';
      }
      if (!values.payerPhone) {
        errors.payerPhone = 'payer phone is required';
      }
      if (!values.careAddress.postCode) {
        errors.careAddress = {
          postCode: 'care address postcode is required',
        };
      }
      if (!values.billingAddress.postCode) {
        errors.billingAddress = {
          postCode: 'billing address postcode is required',
        };
      }
      return errors;
    },
  });

  console.log('errors', formik.errors);

  return (
    <Container maxWidth='md'>
      <PageHeader title='Edit Client Details' link='/client' />

      <form onSubmit={formik.handleSubmit}>
        <Grid
          container
          sx={{
            pb: 3,
            borderBottom: 3,
            borderColor: 'primary.profile',
          }}
        >
          <Grid item xs={12} md={5}>
            <TextInput
              heading='Name of Client'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              required
            />
            <TextInput
              heading='Care seakr relation to client'
              name='seakrClientRelation'
              value={formik.values.seakrClientRelation}
              onChange={formik.handleChange}
              required
            />

            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'grey.200',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'right',
                my: 1,
                pb: 2,
              }}
            >
              <Typography
                variant='h6'
                color='text.primary'
                sx={{ fontWeight: 'bold' }}
              >
                Notes
              </Typography>
              <TextField
                className='inputBorderRadius'
                id='outlined-multiline-static'
                color='success'
                variant='outlined'
                name='notes'
                label=''
                multiline
                rows={6}
                value={formik.values.notes}
                onChange={formik.handleChange}
                required
              />
            </Box>
            <TextInput
              heading='Payer Email'
              disabled
              name='payerEmail'
              value={formik.values.payerEmail}
              onChange={formik.handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={5} justifyContent='flex-end'>
            <TextInput
              heading='Email of Client'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              required
              disabled
            />
            <TextInput
              heading='Contact of Payer'
              name='payerPhone'
              value={formik.values.payerPhone}
              onChange={formik.handleChange}
              required
            />
            <TextInput
              heading="Country (Client's Address)"
              name='careAddress.country'
              value={formik.values.careAddress.country}
              onChange={formik.handleChange}
              required
            />
            <TextInput
              heading="Town (Client's Address)"
              name='careAddress.town'
              value={formik.values.careAddress.town}
              onChange={formik.handleChange}
              required
            />
            <TextInput
              heading="Post Code (Client's Address)"
              name='careAddress.postCode'
              value={formik.values.careAddress.postCode}
              onChange={formik.handleChange}
              required
            />
            <TextInput
              heading='Contact (Alternate/Optional)'
              name='phone'
              value={formik.values.phone}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>

        <Grid
          container
          sx={{
            mt: 3,
            pb: 3,
            borderBottom: 3,
            borderColor: 'primary.profile',
          }}
        >
          <Grid item xs={12} md={5}>
            <TextInput
              heading='Country (Billing Address)'
              name='billing.country'
              value={formik.values.billingAddress.country}
              onChange={formik.handleChange}
            />
            <TextInput
              heading='Post Code (Billing Address)'
              name='billingAddress.postCode'
              value={formik.values.billingAddress.postCode}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={5}>
            <TextInput
              heading='Town (Billing Address)'
              name='billingAddress.town'
              value={formik.values.billingAddress.town}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            mt: 3,
            pb: 3,
            borderBottom: 3,
            borderColor: 'primary.profile',
          }}
        >
          <Grid item xs={12} md={5}>
            <RadioInput
              heading='Type Of Care'
              name='careType'
              value={formik.values.careType}
              handleChange={formik.handleChange}
              data={{ 0: 'Hourly', 1: 'Live In' }}
            />
            <RadioInput
              heading='Payment Type'
              name='paymentMethod'
              value={formik.values.paymentMethod}
              handleChange={formik.handleChange}
              data={{ 1: 'Credit', 0: 'Debit Card' }}
            />
          </Grid>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={5}>
            <RadioInput
              heading='Type of Funding'
              name='fundingType'
              value={formik.values.fundingType}
              handleChange={formik.handleChange}
              data={{
                0: 'Private',
                2: 'Direct Payment (Client)',
                3: 'Direct Payment (Third Party)',
                1: 'NHS',
              }}
            />
            <Button
              variant='contained'
              size='large'
              color='primary'
              type='submit'
              sx={{ mt: 12, width: '100%' }}
            >
              {loading ? (
                <CircularProgress color='inherit' size={24} />
              ) : (
                'Finish'
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

const mapStateToProps = ({
  client: {
    clientDetails: { loading, data },
  },
}) => ({
  detailLoading: loading,
  clientDetails: data,
});

export default connect(mapStateToProps, { updateClient, getClientDetails })(
  UpdateClient
);
