import { CARER } from '../types';

const initialState = {
  loading: true,
  carers: [],
  carerDetails: {
    loading: true,
    data: {
      user: {},
      address: {},
      bankAccount: {},
    },
  },
};

const carerReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CARER.LOADING_START:
      return {
        ...state,
        carers: [],
        loading: true,
      };
    case CARER.FETCH_SUCCESS:
      return {
        ...state,
        carers: payload,
        loading: false,
      };
    case CARER.FETCH_FAILED:
      return {
        ...state,
        carers: [],
        loading: false,
      };
    case CARER.FETCH_CARER_DETAILS:
      return {
        ...state,
        carerDetails: {
          data: payload,
          loading: false,
        },
      };
    case CARER.FETCH_CARER_DETAILS_START:
      return {
        ...state,
        carerDetails: {
          ...state.carerDetails,
          loading: true,
        },
      };
    case CARER.FETCH_CARER_DETAILS_FAIL:
      return {
        ...state,
        carerDetails: initialState.carerDetails,
      };

    case CARER.ADD_CARER:
      return {
        ...state,
        carers: [payload, ...state.carers],
      };
    case CARER.UPDATE_CARER:
      const newData = {
        ...payload,
        user: state.carerDetails.data.user,
        carerClients: state.carerDetails.data.carerClients,
        carerInvoices: state.carerDetails.data.carerInvoices,
      };
      return {
        ...state,
        carerDetails: {
          ...state.carerDetails,
          data: state.carerDetails.data?.id ? newData : state.carerDetails.data,
        },
      };

    case CARER.ADD_CLINT_IN_CARER:
      return {
        ...state,
        carerDetails: {
          ...state.carerDetails,
          data: {
            ...state.carerDetails.data,
            carerClients: [payload, ...state.carerDetails.data.carerClients],
          },
        },
      };

    case CARER.DELETE_CARER:
      return {
        ...state,
        carers: state.carers.filter((item) => item.id !== payload),
      };

    default: {
      return state;
    }
  }
};

export default carerReducer;
