import './App.css';
import { Provider } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import store from './redux/store';
import Navigator from './navigation/Navigator';
const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#5e893c',
      darker: '#053e85',
      profile: '#89BF46',
      pending: '#BE0000',
      paid: '#00AA06',
      approved: '#FFBF00',
      review: '#00559E',
      week: '#808080',
      background: '#EEEEEE',
      background2: '#FBFBFB',
      lineColor: '#BDDC98',
      unassign: '#444444',
      active: '#AFC1A0',
      inactive: '#FFC824',
      deceased: '#BE0000',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
    text: {
      primary: '#686868',
      secondary: '#FFFFFF',
      money: '#759A59',
      date: '#686868',
      temp: '#7E7E7E',
      bold: '#9E9E9E',
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Navigator />
      </ThemeProvider>
    </Provider>
  );
}
export default App;
