import React, { useEffect, useState } from 'react';
import styles from '../../components/carer/listofcarers/CarerDetail.module.css';
import PersonIcon from '@mui/icons-material/Person';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import { FaUserEdit } from 'react-icons/fa';
import { MdError } from 'react-icons/md';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import Status from '../../components/carer/listofcarers/CarerStatus';
import {
  Container,
  Typography,
  Box,
  Grid,
  Modal,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import PageHeader from '../../components/common/PageHeader';
import SubHeading from '../../components/common/SubHeading';
import { connect, useSelector } from 'react-redux';
import LoadingFull from '../../components/common/LoadingFull';
import Nav from '../../components/invoice/NavItem';
import ProfileDetail from '../../components/common/ProfileDetail';
import ContactDetail from '../../components/common/ContactDetail';
import BankDetails from '../../components/carer/BankDetails';
import {
  addCarerClient,
  getCarerDetails,
} from '../../redux/actions/carerActions';
import InvoicesList from '../../components/common/InvoicesList';
import { initial } from '../../components/invoice/invoice';
import { display } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CarerDetail = ({
  carerDetails,
  loading,
  clients,
  addCarerClient,
  match,
  getCarerDetails,
}) => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('md'));

  const [type, setType] = useState(initial);
  const { bankAccount = {} } = carerDetails;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { user } = useSelector(({ auth }) => auth);

  useEffect(() => {
    getCarerDetails(match.params.id);
  }, []);

  if (loading) {
    return <LoadingFull />;
  }

  const { carerClients } = carerDetails || {};
  return (
    <Container maxWidth='md'>
      <PageHeader title='carer Details' link={'/carer'} />
      <Grid container>
        <Grid
          item
          xs={12}
          md={5}
          sx={{
            mb: 2,
          }}
        >
          <ProfileDetail
            type='carer'
            name={carerDetails.user.name}
            address={
              carerDetails.address.town + ', ' + carerDetails.address.country
            }
          />
        </Grid>
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={5}>
          <ContactDetail
            number={carerDetails.phone}
            email={carerDetails.user.email}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={isSmall ? { display: 'flex', flexDirection: 'column-reverse' } : {}}
      >
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              display: 'flex',
              mt: 4,
            }}
            style={isSmall ? { justifyContent: 'center' } : {}}
          >
            {user.superUser && (
              <Link to={`/carer/${match.params.id}/update`}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mr: 3,
                  }}
                  style={isSmall ? { justifyContent: 'center' } : {}}
                >
                  <FaUserEdit className={styles.footerButton} />
                  Edit Details
                </Box>
              </Link>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mr: 3,
              }}
              onClick={handleOpen}
            >
              <MdError className={styles.footerButton} />
              Status
            </Box>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box sx={style}>
                <Status
                  id={carerDetails.id}
                  // disabled={!user.superUser}
                  carerStatus={carerDetails.status}
                  handleClose={handleClose}
                />
              </Box>
            </Modal>
          </Box>
        </Grid>
        <Grid item xs={12} md={2} />
        <Grid item xs={12} md={5}>
          <BankDetails
            name={bankAccount.account_holder_name}
            number={bankAccount}
            routing_number={bankAccount.routing_number}
            bank_name={bankAccount.bank_name}
          />
        </Grid>
      </Grid>

      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'success.light',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 1,
          my: 2,
        }}
      >
        <Typography
          variant='h5'
          color='text.primary'
          sx={{ fontWeight: 'bold' }}
        >
          Assigned client
        </Typography>
        {user.superUser && (
          <Link to={`/carer/${match.params.id}/addclient`}>
            <AddIcon
              sx={{
                fontSize: '3rem',
                fontWeight: '700',
                color: 'primary.profile',
              }}
            />
          </Link>
        )}
      </Box>

      {carerClients.length > 0 ? (
        <Grid container spacing={2}>
          {carerClients.map((item) => (
            <Grid item xs={12} md={6}>
              <Link to={user.superUser ? `/client/${item.id}/assignedcarer` : '#'}>
                <Typography
                  variant='body 1'
                  color='text.primary'
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: 1,
                    borderColor: 'grey.600',
                    py: 2,
                    px: 1,
                  }}
                >
                  <PersonIcon
                    fontSize='large'
                    sx={{ color: 'primary.profile' }}
                  />
                  {item.client.user.name}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      ) : (
        <h3
          style={{
            textAlign: 'center',
            marginTop: 36,
            marginBottom: 36,
            color: '#aaa',
          }}
        >
          No Assigned clients
        </h3>
      )}
      <SubHeading title='Submitted invoices' />
      <Nav setType={setType} />
      <InvoicesList invoices={carerDetails?.carerInvoices} type={type} />
    </Container>
  );
};

const mapStateToProps = ({
  carer: {
    carerDetails: { loading, data },
  },
  client: { clients },
}) => ({
  loading,
  carerDetails: data,
  clients,
});

export default connect(mapStateToProps, {
  addCarerClient,
  getCarerDetails,
})(CarerDetail);
