import axios from 'axios';
import { getConfig, handleErrors } from '../../utils/utils';
import { CLIENT } from '../types';
import {
  createClientCarerConfig,
  getUserCarer,
  getUserClients,
} from './carerActions';
import { API_URL } from '../../constants';
import { toast } from 'react-toastify';

export const getAllClients = (query) => async (dispatch) => {
  try {
    const res = await axios.get(`${API_URL}/api/clients`, {
      params: query,
      ...getConfig(),
    });
    const payload = res.data.data;
    dispatch({ type: CLIENT.FETCH_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: CLIENT.FETCH_FAILED });
    handleErrors(error);
  }
};

export const getClientById = async (id) => {
  const res = await axios.get(`${API_URL}/api/clients/${id}`, getConfig());
  return res.data.data;
};

export const addClientCarer =
  (body, onComplete = () => {}) =>
  async (dispatch) => {
    try {
      const payload = await createClientCarerConfig(body);
      dispatch({ type: CLIENT.ADD_CARER_IN_CLIENT, payload });
      onComplete(true);
    } catch (error) {
      const msg = error.response?.data?.errors?.message || error.message;
      console.error(msg);
      onComplete(false, msg);
    }
  };

const getInvoicesByClientId = async (id) => {
  try {
    const res = await axios.get(`${API_URL}/api/invoices/`, {
      params: {
        published: true,
        clientId: id,
      },
      ...getConfig(),
    });
    return res.data?.data;
  } catch (error) {
    console.log(error.message);
    return [];
  }
};

export const getClientDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: CLIENT.FETCH_CLIENT_DETAILS_START });

    const [client, clientCarers, clientInvoices] = await Promise.all([
      getClientById(id),
      getUserCarer(id),
      getInvoicesByClientId(id),
    ]);
    const payload = {
      ...client,
      clientCarers,
      clientInvoices,
    };

    dispatch({ type: CLIENT.FETCH_CLIENT_DETAILS, payload });
    return client;
  } catch (error) {
    dispatch({ type: CLIENT.FETCH_CLIENT_DETAILS_FAIL });
    handleErrors(error);
  }
};

export const createClient =
  (body, onComplete = () => {}) =>
  async (dispatch) => {
    let newData = {
      seakr: body.seakr,
      seakrClientRelation: body.seakrClientRelation,
      email: body.email,
      payerEmail: body.payerEmail,
      payerPhone: body.payerPhone,
      careAddress: {
        postCode: body.careAddress.postCode,
        country: body.careAddress.country,
        town: body.careAddress.town,
      },
      billingAddress: {
        postCode: body.billingAddress.postCode,
        country: body.billingAddress.country,
        town: body.billingAddress.town,
      },
      notes: body.notes,
      careType: body.careType,
      fundingType: body.fundingType,
      paymentMethod: body.paymentMethod,
    };
    if (body.phone) newData.phone = body.phone;
    const name = body.name;
    const email = newData.email;
    let user = null;
    let userId = null;
    try {
      const authRes = await axios.post(
        `${API_URL}/api/auth/create-account`,
        { name, email, role: '2' },
        getConfig()
      );
      user = authRes.data.data;
      userId = user.id;
    } catch (e) {
      if (e.response.status === 409) {
        userId = e.response.data.userId;
      } else {
        toast.error(e.message);
      }
    }

    if (!userId) {
      toast.error('Not able to create client!');
    }
    try {
      newData['userId'] = userId;
      const carerRes = await axios.post(
        `${API_URL}/api/clients`,
        newData,
        getConfig()
      );
      const payload = { ...carerRes.data.data, user };
      dispatch({ type: CLIENT.ADD_CLIENT, payload });
      onComplete(true);
    } catch (error) {
      const msg = error.response?.data?.errors?.message || error.message;
      console.error(msg);
      onComplete(false, msg);
    }
  };

export const updateClient =
  (id, body, onComplete = () => {}) =>
  async (dispatch) => {
    const {
      seakr,
      seakrClientRelation,
      careAddress,
      billingAddress,
      notes,
      careType,
      fundingType,
      paymentMethod,
      name,
      email,
      userId,
      phone,
      payerEmail,
      payerPhone,
    } = body;
    try {
      const carerRes = await axios.patch(
        `${API_URL}/api/clients/${id}`,
        {
          userId,
          seakr,
          seakrClientRelation,
          careAddress,
          billingAddress,
          notes,
          careType,
          fundingType,
          paymentMethod,
          phone,
          payerEmail,
          payerPhone,
        },
        getConfig()
      );
      const payload = { ...carerRes.data.data };
      dispatch({ type: CLIENT.UPDATE_CLIENT, payload });
      getAllClients()(dispatch);
      onComplete(true);
    } catch (error) {
      const msg = error.response?.data?.errors?.message || error.message;
      console.error(msg);
      onComplete(false, msg);
    }
  };

export const patchClient =
  (id, body, onComplete = () => {}) =>
  async (dispatch) => {
    try {
      const carerRes = await axios.patch(
        `${API_URL}/api/clients/${id}`,
        body,
        getConfig()
      );
      const payload = { ...carerRes.data.data };

      dispatch({ type: CLIENT.UPDATE_CLIENT, payload });
      getAllClients()(dispatch);

      onComplete(true);
    } catch (error) {
      const msg = error.response?.data?.errors?.message || error.message;
      console.error(msg);
      onComplete(false, msg);
    }
  };
