import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Box,
} from '@mui/material';
function RadioInput({ heading, name, value, handleChange, data }) {
  return (
    <FormControl
      sx={{
        width: '100%',
      }}
    >
      <FormLabel>
        <Typography
          variant='h6'
          color='text.primary'
          sx={{ fontWeight: 'bold', mt: 2, mb: 1 }}
        >
          {heading}
        </Typography>
      </FormLabel>
      <RadioGroup name={name} value={value} onChange={handleChange}>
        {Object.keys(data).map(key => (<Box
          sx={{
            border: 1,
            borderColor: 'grey.400',
            px: 2,
            py: 1,
            mb: 1,
          }}
        >
          <FormControlLabel
            value={key}
            control={<Radio />}
            label={data[key]}
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              m: 'auto',
            }}
          />
        </Box>))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioInput;
