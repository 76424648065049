import { USER } from '../types';

const initialState = {
  user: null,
  loading: true,
};
const authReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER.LOADING_START:
      return {
        ...state,
        loading: true,
      };

    case USER.FETCH_SUCCESS:
      return {
        user: payload,
        loading: false,
      };
    case USER.LOGOUT:
    case USER.FETCH_FAILED:
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
      return {
        user: null,
        loading: false,
      };
    default: {
      return state;
    }
  }
};

export default authReducer;
