import React from 'react';
import { Box, Typography } from '@mui/material';
const BankDetails = ({ name, number, routing_number, bank_name }) => {
  return (
    <Box
      sx={{
        border: 2,
        borderColor: 'grey.400',
        height: '225px',
        px: 2,
        py: 1,
        mt: 2,
        boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.25)',
      }}
    >
      <Typography
        variant='h6'
        color='text.primary'
        sx={{
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'left',
          alignItems: 'center',
          mb: 2,
          pb: 2,
        }}
      >
        Bank Details
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'grey.400',
          pb: 1,
        }}
      >
        <Typography
          variant='body1'
          color='primary.profile'
          sx={{
            fontWeight: 'bold',
            borderColor: 'grey.200',
          }}
        >
          Name
        </Typography>
        <Typography
          variant='body2'
          color='text.primary'
          sx={{
            fontWeight: 'bold',
            borderColor: 'grey.200',
          }}
        >
          {name}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'grey.400',
          py: 1,
        }}
      >
        <Typography
          variant='body1'
          color='primary.profile'
          sx={{
            fontWeight: 'bold',
            borderColor: 'grey.200',
          }}
        >
          Acc. No.
        </Typography>
        <Typography
          variant='body2'
          color='text.primary'
          sx={{
            fontWeight: 'bold',
            borderColor: 'grey.200',
          }}
        >
          ***{number?.last4}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'grey.400',
          py: 1,
        }}
      >
        <Typography
          variant='body1'
          color='primary.profile'
          sx={{
            fontWeight: 'bold',
            borderColor: 'grey.200',
          }}
        >
          Bank Code
        </Typography>
        <Typography
          variant='body2'
          color='text.primary'
          sx={{
            fontWeight: 'bold',
            borderColor: 'grey.200',
          }}
        >
          {routing_number}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: 1,
          borderColor: 'grey.400',
          py: 1,
        }}
      >
        <Typography
          variant='body1'
          color='primary.profile'
          sx={{
            fontWeight: 'bold',
            borderColor: 'grey.200',
          }}
        >
          Bank Name
        </Typography>
        <Typography
          variant='body2'
          color='text.primary'
          sx={{
            fontWeight: 'bold',
            borderColor: 'grey.200',
          }}
        >
          {bank_name}
        </Typography>
      </Box>
    </Box>
  );
};

export default BankDetails;
