// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InvoiceDetail_backArrow__3wiDg {\n  z-index: 10;\n}\n", "",{"version":3,"sources":["webpack://src/components/invoice/InvoiceDetail.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb","sourcesContent":[".backArrow {\n  z-index: 10;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backArrow": "InvoiceDetail_backArrow__3wiDg"
};
export default ___CSS_LOADER_EXPORT___;
