import { combineReducers } from 'redux';
import auth from './authReducers';
import carerClientReports from './carerClientReportsReducers';
import carerOriginalInvoiceReports from './carerOriginalInvoiceReportsReducers';
import carer from './carerReducer';
import clientActivityReports from './cientActivityReportsReducers';
import client from './clientReducer';
import invoiceTabReducer from './invoiceTabReducer';
import invoice from './invoiceReducers';
import xeroInvoicingReports from './xeroInvoicingReportReducers';

const rootReducer = combineReducers({
  invoice,
  auth,
  carer,
  client,
  carerClientReports,
  carerOriginalInvoiceReports,
  clientActivityReports,
  xeroInvoicingReports,
  invoiceTabReducer,
});

export default rootReducer;
