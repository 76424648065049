import axios from 'axios';
import { getConfig, handleErrors } from '../../utils/utils';
import { INVOICE } from '../types';
import { API_URL } from '../../constants';

export const fetchAllInvoices = (condition) => async (dispatch, getState) => {
  const prevDate = localStorage.getItem('start-date');

  if (condition.startDate && prevDate !== condition.startDate) {
    dispatch({ type: INVOICE.LOADING_START });
    localStorage.setItem('start-date', condition.startDate);
  }

  try {
    const res = await axios.get(`${API_URL}/api/invoices`, {
      params: condition,
      ...getConfig(),
    });
    // console.log(res.data);
    const payload = res.data.data;
    dispatch({ type: INVOICE.FETCH_SUCCESS, payload });
  } catch (error) {
    dispatch({ type: INVOICE.FETCH_FAILED });

    handleErrors(error);
  }
};

export const getCarerConfigurationById = async (id) => {
  const res = await axios.get(`${API_URL}/api/carer-config/${id}`, getConfig());
  return res.data.data;
};
export const updateCarerConfiguration = async (id, data) => {
  const newData = {
    carerId: data.carerId,
    clientId: data.clientId,
    payment: {
      grossCarerFee: data.grossCarerFee,
      seakrFee: data.seakrFee,
      netCarerPayment: data.netCarerPayment,
    },
    tax: {
      grossCarerTax: data.grossCarerTax,
      seakrTax: data.seakrTax,
      netTax: data.netTax,
    },
    holidayRates: {
      grossCarerRates: data.holidayGrossCarerRates,
      seakrRates: data.holidaySeakrRates,
      netCarerPayment: data.holidaysNetCarerPayment,
    },
    customRates: {
      grossCarerFee: data.customGrossCarerFee || 0,
      seakrFee: data.customSeakrFee || 0,
      customRatesRule: data.customRatesRule,
      netCarerPayment: data.customNetCarerPayment || 0,
    },
  };
  const res = await axios.patch(
    `${API_URL}/api/carer-config/${id}`,
    newData,
    getConfig()
  );
  return res;
};

export const getCarerAccountDetails = (id) => async (dispatch) => {
  try {
    let payload = await axios.get(`${API_URL}/api/carers/${id}`, getConfig());
    payload = payload.data.data.bankAccount;

    dispatch({ type: INVOICE.FETCH_INVOICE_BANK_ACCOUNT, payload });
  } catch (error) {
    handleErrors(error);
  }
};

export const fetchInvoiceDetails = (id) => async (dispatch) => {
  dispatch({ type: INVOICE.FETCH_INVOICE_DETAILS_START });
  let payload = {};
  try {
    const res = await axios.get(`${API_URL}/api/invoices/${id}`, getConfig());
    payload = res.data.data;

    const extraExpenses = [];
    payload.serviceData.forEach((item) => {
      item.mileage.forEach((data) => {
        extraExpenses.push({
          day: item.day,
          type: 'Mileage',
          ...data,
        });
      });
      item.otherExpenses.forEach((data) => {
        extraExpenses.push({
          day: item.day,

          type: 'Other Expenses',
          ...data,
        });
      });
    });

    dispatch({
      type: INVOICE.FETCH_INVOICE_DETAILS,
      payload: {
        ...payload,
        extraExpenses,
      },
    });
  } catch (error) {
    dispatch({ type: INVOICE.FETCH_INVOICE_DETAILS_FAILED });

    handleErrors(error);
  }

  return payload;
};

export const sendInvoiceFeedback = (id, data) => (dispatch) => {
  return axios.patch(
    `${API_URL}/api/invoices/${id}/feedback/`,
    data,
    getConfig()
  );
};
