import { colors } from '../constants/colors';
import * as XLSX from 'xlsx';

export const handleErrors = (error) => {
  const msg = error.response?.data?.message || error.message;
  console.error(msg);
};

export const isEmail = (email) => {
  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const isAccountNumber = (acc) => {
  // let re = /^[0-9]{2}(?:[0-9]{9}|-[0-9]{3}-[0-9]{6})$/;

  return acc.length > 10;
};
export const isIFSC = (code) => {
  let re = /^[A-Z]{4}0[A-Z0-9]{6}$$/;
  return re.test(String(code));
};

export const getConfig = () => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  };
};

export const isEmpty = (item) => {
  if (item?.length) {
    for (let i = 0; i < item.length; i++) {
      if (item[i] === '') {
        return true;
      }
    }
  }
  return false;
};
//validate phone number
export const isContact = (contact) => {
  let re = /^[0-9]{10}$/;
  return !re.test(String(contact));
};

export const getInvoiceHeaderBackground = (status) => {
  return status === 4
    ? colors.success
    : status === 3
    ? colors.danger
    : status === 1
    ? colors.yellow
    : colors.blue;
};

export const getInvoiceHeaderType = (status) => {
  return status === 4
    ? 'Paid'
    : status === 3
    ? 'Pending'
    : status === 1
    ? 'Under Review'
    : 'Approved';
};

export const getFormattedString = (string = '', length = 14) => {
  if (string.length < length) return string;
  return `${string.substring(0, length)?.trim()}..`;
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const filterByDate = (reports = [], dateRange) => {
  if (!dateRange) return reports;

  return reports.filter((item) => {
    const startDate = new Date(item.start).getTime();

    const rangeStart = new Date(dateRange.startDate).getTime();
    const rangeEnd = new Date(dateRange.endDate).getTime();

    return rangeStart <= startDate && startDate <= rangeEnd;
  });
};

export const downloadAsExcel = (reports) => {
  const workSheet = XLSX.utils.json_to_sheet(reports);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, 'Reports');
  XLSX.writeFile(workBook, 'reports.xlsx');
};
