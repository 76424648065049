import { Container, Box, Typography } from '@mui/material';
import clientIcon from '../assests/clientIcon.svg';
import carerIcon from '../assests/carerIcon.svg';
import React from 'react';
import { Link } from 'react-router-dom';
// import styles from "./styles.module.css";

const styles = {
  my: 5,
  display: 'flex',
  justifyContent: 'space-between',
};

const ReportCard = ({ link, icon, title }) => (
  <Typography
    variant='h6'
    color='text.primary'
    sx={{
      border: 2,
      borderColor: 'grey.500',
      width: '40%',
      py: 2,
      px: 2,
      borderRadius: 1,
    }}
  >
    <Link
      to={link}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
      }}
    >
      {title}
      <img
        src={icon}
        alt='carer'
        width='35px'
        style={{
          filter:
            'invert(53%) sepia(4%) saturate(6983%) hue-rotate(45deg) brightness(93%) contrast(90%)',
        }}
      />
    </Link>
  </Typography>
);

const Report = () => {
  return (
    <Container maxWidth='md'>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'success.light',
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
          pb: 1,
          my: 2,
        }}
      >
        <Typography
          variant='h5'
          color='text.primary'
          sx={{ fontWeight: 'bold' }}
        >
          report
        </Typography>
      </Box>
      <Box sx={styles}>
        <ReportCard
          title='Carer Report'
          link='/report/carer'
          icon={carerIcon}
        />
        <ReportCard
          title='Client Report'
          link='/report/client'
          icon={clientIcon}
        />
      </Box>
      <Box sx={styles}>
        <ReportCard
          title='Client Activity Report'
          link='/report/client-activity'
          icon={carerIcon}
        />
        <ReportCard
          title='Carer Original Invoice Report'
          link='/report/carer-original-invoice'
          icon={clientIcon}
        />
      </Box>

      <Box sx={styles}>
        <ReportCard
          title='Xero Invoicing Report'
          link='/report/xero-invoicing'
          icon={carerIcon}
        />
      </Box>
    </Container>
  );
};

export default Report;
