import styles from "./nav.module.css";
import { Link } from "react-router-dom";
import { Button, Container, Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
function Navbar({ type, link }) {
  return (
    <Container disableGutters={true}>
      <Box
        borderBottom={3}
        height={1}
        sx={{
          color: "#cde4b2",
          mt: 3,
          pb: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link to={link}>
          <Button
            endIcon={<AddIcon />}
            variant="contained"
            size="large"
            color="primary"
            className={styles.setupClient}
          >
            Setup {type}
          </Button>
        </Link>
        <Typography
          variant="h5"
          align="center"
          color="textPrimary"
          sx={{ fontWeight: "bold", fontSize: 30 }}
        >
          {type}s
        </Typography>
      </Box>
    </Container>
  );
}

export default Navbar;
